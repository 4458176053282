<template>
  <aside :class="{'expanded':clickedBool}">
    <ol class="dots"><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ol>
    <h4>
      <span>{{ workAside.title }}</span>
    </h4>
    <dt><span>Description</span></dt>
    <dd>
      <p><span id="sp_desc">{{workAside.desc}}</span></p>
      <p><span id="sp_techdesc">{{workAside.techdesc}}</span></p>
      <p><a id="link__live"
           target="_blank"
           :href="workAside.link"
           v-on:mouseover="setHover(true)"
           v-on:mouseout="setHover(false)"
           :class="['link__live-link',{'link-codepen':isPen}]"
      ><em></em></a></p>
    </dd>

    <ol class="list__tech">
      <li v-for="(itm, index) in workTech" :key="index">
        <em :data-type="itm" :class="'icon-' + itm"></em>
      </li>
    </ol>

      <a :href="workAside.gitlink" target="_blank" class="link__github-link"
      ><em></em></a>

    <a @click="setClickBool" :class="['link__expand',{'sho':clickedBool}]"></a>
  </aside>
</template>
<script>
export default {
  name: "Aside",
  props:['isPen'],
  data(){
    return{
      clickedBool:false
    }
  },
  methods:{
      setClickBool(){
        this.clickedBool = !this.clickedBool;
        this.$emit('clicked',this.clickedBool);
      },
      setHover(bool){
        this.$emit('workBlockState',bool);
      }
  },
  computed: {
    workTech() {
      return this.$store.state.workItem.techStack;
    },
    workAside() {
      return this.$store.state.workItem.aside;
    },
  },
};
</script>

<style lang="scss">
.wrapper__work {
  .wrap__work-outer {
    width: 960px;
    min-height: 600px;
    position: relative;
    > aside {
      width: 300px;
      min-height: 440px;
      position: absolute;
      top: 10%;
      right: -10%;
      z-index: 1;
      box-shadow: -2px 4px 28px #ccc;
      padding: 40px 20px 90px 20px;
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        background: #fff !important;
        z-index: -1;
        opacity: 0.9;
      }
      hr{
        margin:12px 0px 0px 0px;
        height:0px;
        background:#eee;
        border-color:#eee;
        border-top:dotted 1px #ccc;
      }
        .link__expand{
            display:none;
            position:absolute;
            color:#666;
            background:#fff;
            outline:solid 4px #eee;
            border-radius:20px;
            font-size:15px;
            padding:4px 40px;
            left:50%;
            z-index:2;
            top: 90px;
            transform:translateX(-50%);
           
            &:before{
              content:'+';
              display:inline-block;
              font-size:20px;
              font-weight:bold;
            }
            &:after{
              content:' expand'
            }
        }
        .link__expand.sho{
            &:before{
              content:'-';
              font-size:22px;
            }
            &:after{
              content:' collapse'
            }
        }
      h4{font-size:18px;}
      h4,
      dt {
        position: relative;
        > span {
          transition: filter 0.1s ease;
        }
        &:after {
          content: "";
          width: 40px;
          height: 5px;
          background: orange;
          position: absolute;
          top: -10px;
          left: 0px;
        }
      }
      h4 {
        &:before {
          content: "Title: ";
          color: #999;
        }
      }
      dt,
      dd {
        text-align: left;
      }
      dt {
        margin-top: 35px;
        color: #333;
      }
      dd {
        line-height: 20px;
        position: relative;
        font-size: 13px;
        &:after{
          content:'';
          display:none;
          position:absolute;
          top:-5px;
          background:#fff;
          left:0px;right:0px;
          height:55px;
          width:110%;
          z-index:1;
          background:linear-gradient(to bottom, transparent, #fff);
        }
        p{
          margin-bottom:12px;
          line-height:19px;
        }
      }
      a.link__github-link{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: auto;
        min-width:110px;
        position: absolute;
        border-radius:30px;
        padding:2px 15px 3px 15px;
        color:#333;
        overflow: hidden;
        bottom:15px;right:12px;top:auto;
        font-size:14px;
        outline:solid 2px #fff;
      
        em{
          display:block;
          width:20px;
          height:20px;
          border-radius:24px;
          background:url('../assets/images/portfolio/githubicon.svg') no-repeat center;
          background-size:cover;
          position: absolute;
          opacity:.75;
          right:4px;
          top:2px;
        }
        &:before{
          content:'';
          display:block;
          position:absolute;
          left:50%;
          transform:translateX(-50%);
          z-index: -1;
          width:100%;
          height:100%;
          background-color:#eee;
        }
        &:after{
          content:'view code';
          position: relative;
        }
        &:hover{
          outline:solid 1px #eee;
          em{
            opacity:1;
          }
          &:before{
            background-color:#ddd;
          }
        }
      }
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        background: #fff !important;
        z-index: -1;
        opacity: 0.9;
      }
      a.link__live-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        position: relative;
        margin-top:8px;
        transform: translateX(-50%);
        left: 50%;
        max-width: 185px;
        padding:7px 16px 8px 16px;
        text-align: center;
        outline: solid 10px #fff;
        font-size:16px;
        background: #4f73bc;
        overflow:hidden;
        text-decoration: underline;
        color: #fff;
        border-radius: 60px;
        &:after{
          content:'live demo';
          position: relative;
        }
        &:before {
          content: "";
          width: 0%;
          height: 0%;
          display: block;
          position: absolute;
          z-index: -1;
          left: 50%;
          top: 50%;
          mix-blend-mode: multiply;
          background: #142b58;
          transform: translate(-50%, -50%);
          transition: all 0.1s ease;
        }
        &:hover {
          color: #fff;
          transform: translateX(-50%) scale(1.05);
          mix-blend-mode: multiply;
          padding:8px 16px 8px 16px;
          opacity: 0.9;
          &:before {
            width: 100%;
            height: 100%;
          }
        }
      }
      a.link__live-link.link-codepen{
        background: #444;
        height:34px;
        padding:0px;
        &:after{
          content:'codepen';
          text-decoration: underline;
          width:100%;
          height:100%;display: flex;
          align-items: center;
          justify-content:center;
          position:absolute;
          color:#fff;
          background-image:url('../assets/images/codepenIcon.svg');
          background-repeat: no-repeat;
          background-position: 14px 5px;
          background-size: 24px 24px;
          z-index: 999;
        }
      }
    }
    > aside.expanded{
      dd{
        &:after{
          display:none;
        }
      }
    }
  }
}

.wrapper__work.loading {
  .wrap__work-outer {
    > aside {
      h4,
      dd {
        span {
          filter: blur(1px);
          transition: filter 0.1s ease;
        }
      }
    }
  }
}

.wrapper__work aside {
  ol.dots {
    display: flex;
    position: absolute;
    top: 10px;
    right: 20px;
    > li {
      list-style: none;
      width: 10px;
      height: 5px;
      background: #eee;
      margin-right: 5px;
    }
  }
}

ol.list__tech {
  position: absolute;
  margin: 0px;
  padding: 0px;
  width: 190px;
  height: 30px;
  bottom: 10px;
  left: -10px;
  display: flex;
  justify-content: flex-start;
  z-index: 2;
  transform: scale(0.75) translateX(-5px);
  >li{
    display: block;
    list-style: none;
    height: 25px;
    width: 28px;
    margin-right: 8px;
    position: relative;
  }
  &:before {
    content: "made with ...";
    font: normal 16px verdana;
    position: absolute;
    margin: 0px;
    padding: 0px;
    left: -23px;
    top: -40px;
    color: #777;
    z-index: 2;
    background-color: #eee;
    padding: 4px 12px;
    min-width: 190px;
    text-align: left;
    border-left: solid 5px #999;
  }
  &:after{
    content:'';
    display: block;
    position: relative;
    background-image:url('../assets/images/paperClip.png');
    background-repeat: no-repeat;
    background-size:18px 16px;
    background-position: 0 0;
    width:20px;height:17px;
    left:-15%;
    top: -46px;
    z-index: 3;
  }
  
}



@keyframes loveing {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0.6;
    transform: scale(1.1);
  }
}


.wrapper__work.work1 aside ol.dots > li:nth-child(1) {
  background: orange;
}
.wrapper__work.work2 aside ol.dots > li:nth-child(2) {
  background: orange;
}
.wrapper__work.work3 aside ol.dots > li:nth-child(3) {
  background: orange;
}
.wrapper__work.work4 aside ol.dots > li:nth-child(4) {
  background: orange;
}
.wrapper__work.work5 aside ol.dots > li:nth-child(5) {
  background: orange;
}
.wrapper__work.work6 aside ol.dots > li:nth-child(6) {
  background: orange;
}
.wrapper__work.work7 aside ol.dots > li:nth-child(7) {
  background: orange;
}

aside ol li,
footer p{
    >em {
      display: block;
      height: 25px;
      width: 30px;
      position: absolute;
      top: 0px;
      left: 0px;
      background-position: 0px -3px;
      background-repeat: no-repeat;
      background-size: 653px 30px;
      background-image: url("../assets/images/sprite/skills-svg-sprite.svg");
      &:before,
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
      &:after {
        width: 0px;
        height: 0px;
        border-bottom: solid 8px #220000;
        border-right: solid 8px transparent;
        border-left: solid 8px transparent;
        bottom: -10px;
        left: 16px;
      }
      &:before {
        content: attr(data-type);
        background: #220000;
        padding-top:6px;
        font: normal 18px verdana;
        width: 120px;
        height: 39px;
        color: #fff;
        bottom: -48px;
        left: -40px;
        border-radius: 4px;
        text-align: center;
       
      }
      &:hover {
        animation: none !important;
        &:before,
        &:after {
          display: block;
        }
      }
    }
     em.icon-js {
      background-position: 0px -3px;
    }
     em.icon-php {
      background-position: -33px -3px;
      width: 34px;
    }
     em.icon-scss {
      background-position: -179px -3px;
    }
     em.icon-vue {
      background-position: -222px -3px;
    }
     em.icon-jquery {
      background-position: -271px -3px;
    }
     em.icon-python {
      background-position: -406px -3px;
    }
     em.icon-django {
      background-position: -135px -3px;
    }
     em.icon-express {
      background-position: -601px -3px;
    }
     em.icon-node {
      background-position: -540px -3px;
    }
     em.icon-d3 {
      background-position: -360px -3px;
    }
     em.icon-love {
      background-position: -576px -3px;
      animation: loveing 0.5s infinite alternate;
    }
}
</style>