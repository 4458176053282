<template>
  <section
    :class="['sect sect__home', getTypeClass()]"
    id="sect_home"
    data-type="home">
    <p class="txt__temperature">
      <em :class="['icon_weather', { weatherIcon }]"></em> {{ temp }}'F in
      Chicago
    </p>
    <div class="flex mobile-col">
      <div class="flex flex-col fx1">
        <div class="box__helloworld">
          <h2>Hello World</h2>
          <div class="wrapper__spotdog">
            <div class="block__spotdog">
              <em id="em_spotdog"></em>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col fx2">
        <div class="box__uxdev">
          <ul class="list__toggle-uxdev" id="list_toggle-uxdev">
            <li>
              <fieldset class="fieldset__uxdev ux">
                <a
                  href="javascript:void(0)"
                  @click="sliderToggle($event)"
                  class="link__switch switch-ux"
                >
                  <h2>UX</h2>
                  <p>SPOT</p>
                </a>
                <div class="divider">
                  <input
                    type="checkbox"
                    class="chx__slider"
                    id="chx_slider"
                    @click="
                      switchSpot(
                        $event,
                        typeClass === 'switch-dev' ? 'switch-ux' : 'switch-dev'
                      )
                    "
                  />
                </div>
              </fieldset>
            </li>
            <li>
              <fieldset class="fieldset__uxdev dev">
                <a
                  href="javascript:void(0)"
                  @click="sliderToggle($event)"
                  class="link__switch switch-dev"
                >
                  <h2>DEV</h2>
                  <p>c://spot/run</p>
                </a>
              </fieldset>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div :class="['sun', sunClass]" id="sun"><div class="inner-sun"></div></div>
    <div class="tweet" @click="openTweet()">
      <dt>
        <a
          href="https://twitter.com/sonnykoh"
          target="_blank"
          style="
            display: block;
            margin-left: 6px;
            text-align: left;
            color: #341d0d;
            font: bold 12px arial;
            text-decoration: underline;
          "
          >{{ tweet.tweet }}</a
        >
      </dt>
      <dd
        style="
          font-size: 11px;
          padding: 6px 0px 0px 6px;
          text-align: left;
          color: #333;
        "
      >
        tweeted on: {{ tdate }}
      </dd>
      <em></em>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  props: ["vmode"],
  name: "Home",
  data() {
    return {
      temp: 0,
      typeClass: this.vmode,
      weatherIcon: "cloud",
      tdate: {
        type: String,
      },
      tweet: {
        type: Object,
      },
    };
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("llll");
      }
    },
    playSwitchAudio() {
      let $sound = document.getElementById("switchSound");
      if ($sound) $sound.play();
    },

    openTweet() {
      window.open("https://twitter.com/sonnykoh");
      return;
    },

    sliderToggle(evt) {
      evt.preventDefault();

      let $type = document
        .getElementById("sect_home")
        .classList.contains("switch-ux")
        ? "switch-dev"
        : "switch-ux";
      this.switchSpot(evt, $type);
    },

    switchSpot(evt, _type) {
      evt.preventDefault();
      this.$emit("clicked", (this.typeClass = _type));
      this.addClassApp();
      this.playSwitchAudio();
      return false;
    },

    addClassApp() {
      let app = document.getElementById("app");
      this.typeClass === "switch-dev"
        ? app.classList.add("switch-dev")
        : app.classList.remove("switch-dev");
    },
    getTypeClass() {
      return this.typeClass;
    },
  },
  mounted() {
    var $timer = setTimeout(() => {
      this.sunClass = "ux";
      document.querySelectorAll(".img-port").forEach((el, index) => {
        el.src = index !== 0 ? el.dataset.src : el.src;
      });
      clearTimeout($timer);
    }, 1500);

    axios
      .get(
        "https://api.openweathermap.org/data/2.5/weather?q=chicago&appid=613a03e81c210096b58b3bb9f85dd4a1"
      )
      .then((res) => {
        this.temp = Math.floor((([res.data.main.temp] - 273.15) * 9) / 5 + 32);
        this.weatherIcon = "cloud";
        // var $tmptag = "";
        // if (this.temp <= 100) {
        //   $tmptag = "warm ";
        // }
        // if (this.temp <= 70) {
        //   $tmptag = "pleasant ";
        // }
        // if (this.temp <= 60) {
        //   $tmptag = "brisk ";
        // }
        // if (this.temp <= 50) {
        //   $tmptag = "chilly ";
        // }
        // if (this.temp <= 30) {
        //   $tmptag = "cold ";
        // }

        // this.temptag = $tmptag;
      });

    axios
      .get("../tweets/index.php", {
        mode: "no-cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.tweet = res.data;
        this.tdate = this.format_date(res.data.created);
      });
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/global.scss";

$devBg: #00736f;
$devColor: #9cf0db;
$devBar: #9cf0db;
$homeYellow: #fbb000;
$helloBg: #a54c00;

@keyframes toggleDog {
  0% {
    background-image: url(../assets/images/dogAnim1.png);
  }

  20% {
    background-image: url(../assets/images/dogAnim3.png);
  }
  35% {
    background-image: url(../assets/images/dogAnim4.png);
  }
  60% {
    background-image: url(../assets/images/dogAnim5.png);
  }

  90% {
    background-image: url(../assets/images/dogAnim6.png);
  }
  100% {
    background-image: url(../assets/images/dogAnim7.png);
  }
}

@keyframes toggleDogDev {
  0% {
    background-image: url(../assets/images/dogAnimDev1.png);
    transform: scale(1);
  }

  30% {
    background-image: url(../assets/images/dogAnimDev2.png);
    transform: scale(1.02);
  }
  80% {
    background-image: url(../assets/images/dogAnimDev2a.png);
    transform: scale(1.02);
  }
  100% {
    background-image: url(../assets/images/dogAnimDev3.png);
    transform: scale(1);
  }
}

@keyframes sunshine {
  0% {
    transform: rotate(0deg) scale(1);
    top: 6.5%;
    right: 5%;
    box-shadow: 0px 0px 20px 5px lighten($home, 20%);
  }
  100% {
    transform: rotate(180deg) scale(1.12);
    top: 6.8%;
    right: 6%;
    box-shadow: 0px 0px 20px 20px lighten($home, 20%);
  }
}
@keyframes sunshineDev {
  0% {
    opacity: 0.75;
    transform: rotate(-1deg) scale(1);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg) scale(1.06);
  }
}

section.sect__home {
  background: $homeYellow;
  position: relative;
  top: 40px;
  p.txt__temperature {
    font: normal 11px verdana;
    display: flex;
    min-width: 100px;
    white-space: nowrap;
    height: 40px;
    justify-content: flex-end;
    align-items: flex-start;
    color: #777;
    position: absolute;
    top: -35px;
    right: 25px;
    z-index: 9999999999;
    em.icon_weather {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 4px;
      position: relative;
      color: #ccc;
      &:before {
        content: "";
        font-size: 14px;
        display: block;
        color: #ccc;
        width: 20px;
        height: 20px;
        margin-top: -4px;
        position: absolute;
      }
    }
    em.icon_weather.cloud {
      &:before {
        content: "\2601";
      }
    }
  }

  div.tweet {
    position: absolute;
    top: calc(100vh - 80px);
    left: 90px;
    width: 90vw;
    max-width: 300px;
    font: normal 12px verdana;
    padding: 8px 8px 2px 8px;
    z-index: 99999999;
    &:hover {
      cursor: pointer;
      top: calc(100vh - 100px);
      transition: top 0.15s ease-in-out;
    }
    em {
      display: block;
      width: 43px;
      height: 35px;
      position: absolute;
      opacity: 0.9;
      mix-blend-mode: multiply;
      background-image: url("../assets/images/tweet.svg");
      background-repeat: no-repeat;
      background-size: 43px 35px;
      z-index: 99999999;
      top: 6px;
      left: -46px;
      transform: scale(0.4) rotate(10deg);
    }
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;
      background: #fff;
      display: block;
      position: absolute;
      opacity: 0.3;
      top: 0px;
      left: 0px;
      border-right: solid 1px #444;
    }
    &:after {
      content: "";
      width: 0px;
      display: block;
      border-bottom: solid 15px #fff;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      position: absolute;
      left: -16px;
      top: 10px;
      opacity: 0.3;
      z-index: 99;
      transform: rotate(-90deg);
    }
  }

  /*end tweet */
  .flex {
    position: relative;
    display: flex;
    .fx2 {
      min-height: 100vh;
      * {
        z-index: 1;
      }
    }

    div.box__helloworld {
      width: 135%;
      height: 120px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: 20%;
      left: 0px;
      &:after {
        content: "";
        position: absolute;
        display: block;
        left: 58px;
        top: 0px;
        background: #fff;
        width: 15px;
        height: 100%;
        z-index: 11;
      }
      &:before {
        content: "";
        z-index: 10;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        left: 0%;
        background-color: $helloBg;
        transform: translateX(-100%);
      }
      .wrapper__spotdog {
        width: 265px;
        display: block;
        position: absolute;
        height: 120px;
        right: -310px;
        left: auto;
        top: 0px;

        .block__spotdog {
          width: 265px;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 2px);
          em {
            width: 265px;
            height: 130px;
            position: absolute;
            display: block;
            top: 5px;
            left: 0px;

            opacity: 0;
            background-position: center;
            background-repeat: no-repeat;
            // border:solid 1px #cc0000;
            background-image: url(../assets/images/dogAnim1.png);
            background-size: 76%;
            animation: toggleDog 1s infinite alternate;
          }
        }
      }

      h2 {
        font-size: 325%;
        font-weight: bold;
        color: $homeYellow;
        line-height: 90%;
        text-transform: uppercase;
        margin: 0px;
        padding-left: 50px;
        z-index: 11;
      }
    }

    .box__uxdev {
      position: relative;
      display: flex;
      height: 90%;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: -15%;

      div.divider {
        width: 1px;
        position: absolute;
        display: block;
        height: 300px;
        position: absolute;
        left: 99%;
        top: -8%;

        &:before {
          content: "";
          display: block;
          height: 0px;
          width: 2px;
          position: absolute;
          top: 55%;
          left: 50%;
          background: #fff;
          transform: translate(-50%, -50%);
        }

        input.chx__slider {
          width: 75px;
          height: 28px;
          position: absolute;
          right: -36px;
          top: 50%;
          z-index: 99;
          border: solid 2px lighten($homeYellow, 10%);
          display: block;
          appearance: none;
          transform: none;
          outline: solid 10px $homeYellow;
          border-radius: 15px;
          background: $homeYellow;
          background: $helloBg;
          &:hover {
            cursor: pointer;
          }
          &:after {
            content: "";
            width: 22px;
            height: 17px;
            position: absolute;
            left: 5px;
            top: 3px;
            background: #fef1a8;
            pointer-events: none;
            border-radius: 24px;
            transition: left 0.2s ease-in-out;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      ul.list__toggle-uxdev {
        display: flex;
        width: 100%;
        margin: 0px;
        padding: 0px;
        justify-content: space-between;
        margin-bottom: 7.5%;
        max-width: 1000px;

        > li {
          margin: 0px;
          padding: 0px;
          list-style: none;
          width: 100%;
          display: flex;
          flex: 1;
          //background:brown;
          .fieldset__uxdev {
            margin: 0px;
            padding: 0px;
            width: 100%;
            min-height: 300px;
            position: relative;
            border: none;
            opacity: 0;
            > a {
              height: 100%;
              position: relative;
              display: block;
              width: 100%;
              min-height: 300px;
              text-decoration: none;
              // background:pink;
              h2 {
                font-size: 175px;
                font-weight: 600;
                letter-spacing: 0.03em;
                position: absolute;
                margin: 0px;
                color: #fef1a8;
                left: 0px;
                top: 30px;
                &:before,
                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  height: 2px;
                  opacity: 0.4;
                }
                &:after {
                  width: 30px;
                  background: #fff;
                  top: -11px;
                  left: -25px;
                  transform: rotate(45deg);
                }
                &:before {
                  border-bottom: solid 2px #fff;
                  border-right: solid 2px #fff;
                  width: 12px;
                  height: 12px;
                  left: -12px;
                  top: -12px;
                }
              }
              p {
                color: #fff;
                position: absolute;
                margin: 0px;
              }
            }
          }

          .fieldset__uxdev.ux {
            > a {
              display: flex;

              h2 {
                letter-spacing: 0.05em;
                position: relative;
                font-size: 175px;
                &:before,
                &:after {
                  border-color: #fff;
                  opacity: 1;
                }
                &:after {
                  background-color: #fff;
                }
              }
              > p {
                top: 120px;
                margin-left: 1%;
                font-size: 48px;
                font-weight: bold;
                display: block;
                position: relative;
                text-align: left;
                width: 150px;
                &:before,
                &:after {
                  content: "see";
                  position: absolute;

                  top: -50px;
                  left: 0px;
                  text-transform: uppercase;
                }
                &:after {
                  content: "run";
                  top: 48px;
                }
              }
            }
          }
          .fieldset__uxdev.dev {
            > a {
              > h2 {
                font-size: 160px;
                left: 20%;
                padding-top: 25px;
              }
              &:hover {
                > h2 {
                  transform: scale(1.05);
                  &:before,
                  &:after {
                    opacity: 1;
                  }
                  &:before {
                    left: -7px;
                    top: -1px;
                  }
                  &:after {
                    left: -20px;
                    top: 0px;
                  }
                }
              }
              > p {
                font-size: 37px;
                top: 60px;
                left: 21%;
                font-family: "courier new";
                letter-spacing: -0.05em;
              }
            }
          }
        }
      }
    }
  }
  div.sun {
    display: block;
    background: #ffb916;
    width: 200px;
    height: 200px;
    border-radius: 200px;
    position: absolute;
    top: 6.5%;
    right: 5.5%;
    box-shadow: 0px 0px 30px 50px #ffc12f;
    opacity: 0;
    animation: sunshineDev 1s infinite alternate;
    &:after {
      content: "";
      display: block;
      width: 186px;
      height: 186px;
      border-radius: 190px;
      position: absolute;
      border: dashed 2px #ffd062;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: border 0.2s ease-in-out;
    }
  }
}

#app {
  section.sect__home.dot1 {
    .flex {
      div.box__helloworld {
        &:before {
          transform: translateX(0%);
          transition: transform 1s ease;
        }
      }
    }
  }
}

section.sect__home.switch-dev {
  background: $devBg;
  color: $devColor;
  div.box__helloworld {
    .wrapper__spotdog {
      .block__spotdog {
        > em {
          opacity: 0.9;
          animation: toggleDogDev 0.75s infinite alternate;
          &:before,
          &:after {
            content: "<";
            color: #3d9394;
            font: normal 60px courier;
            position: absolute;
            opacity: 1;
            left: 15px;
            top: 20px;
          }
          &:after {
            content: ">";
            left: auto;
            right: 0px;
          }
        }
      }
    }
  }

  .flex .box__uxdev div.divider > input.chx__slider {
    background: darken($devBg, 10%);
    border-color: lighten($devBg, 10%);
    outline: solid 10px $devBg;
    &:after {
      left: 43px;
      background: #fff;
    }
  }
  div.sun {
    background: #00706d;
    box-shadow: 0px 0px 20px 10px #007d79;
    > div {
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &:after {
        content: "✭";
        color: #afe2e1;
        opacity: 0.09;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        font-size: 200px;
      }
    }
    &:after {
      border-color: #9cf0db;
      border-style: dotted;
      opacity: 0.3;
    }
  }

  .flex {
    .box__uxdev {
      h2,
      p {
        color: $devColor;
        text-shadow: 0px 1px 1px darken($devBg, 5%);
      }
      .fieldset__uxdev.ux {
        > a {
          h2 {
            color: #fff;
            &:after,
            &:before {
              opacity: 0.4 !important;
            }
            &:before,
            &:after {
              border-color: #fff !important;
            }
            &:after {
              background-color: #fff !important;
            }
          }
          &:hover {
            h2 {
              transform: scale(1.05);
              transition: all 0.1s ease;
              &:after,
              &:before {
                opacity: 1 !important;
              }
              &:before {
                left: -7px;
                top: -1px;
              }
              &:after {
                left: -20px;
                top: 0px;
              }
            }
          }
        }
      }
      .fieldset__uxdev.dev {
        > a {
          h2 {
            color: #fff;
            transform: scale(1);
            &:after,
            &:before {
              opacity: 1;
            }
            &:before,
            &:after {
              border-color: #fff;
            }
            &:after {
              background-color: #fff;
            }
          }
          &:hover {
            h2 {
              transform: scale(1) !important;
              &:after {
                top: -11px !important;
                left: -25px !important;
              }
              &:before {
                left: -12px !important;
                top: -12px !important;
              }
            }
          }
        }
      }
    }
    div.box__helloworld {
      h2 {
        color: lighten($devBg, 10%);
        font: normal 400% "courier new";
      }
      &:before {
        background: darken($devBg, 10%);
      }
    }
  }
}

body.home {
  section.sect__home {
    div.flex .box__uxdev div.divider {
      &:before {
        height: 300px;
        transition: height 1.2s ease-in;
      }
    }
    div.box__helloworld {
      &:before {
        transform: translateX(0%);
        transition: transform 0.7s ease-in-out;
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  body {
    section.sect__home {
      .sun {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        position: absolute;
        top: 6.5%;
        right: 5%;
        box-shadow: 0 0 20px 30px #ffc12f;
        &:after {
          content: "";
          width: 160px;
          height: 160px;
          border-radius: 160px;
          border: 2px dashed #ffd062;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: border 0.2s ease-in-out;
        }
      }
    }
    section.sect__home.switch-dev div.sun > div::after {
      font-size: 110px;
    }
  }
}

</style>
