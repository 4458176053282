import { createStore } from "vuex";

export default createStore({
  state: {
    $: (id) => document.getElementById(id),
    type: "home",
    isMobile: false,
    isCodePen:false,
    switchMode: "switch-ux",
    pageWidth:"400px",
    workLoadingBool: true,
    workItem: {},
    emailMessObj: {},
    about: {
      do: {
        para1: "I sketch ideas, spit out code, and solve problems. I fix broken links and update functionality. I make dull things into pretty things and turn confusing choices into familar patterns. I like to make things; break things; and then improve the process of making things. I develop software; improve software and advocate for users of software.", 
        para2: "Humans and computers don't always see eye-to-eye. I make sure they play nice."},
      am: {
        para1:"I was born in South Korea; raised in Chicago; lived in Los Angeles; and traveled extensively in South America.",
        para2:"I am an avid outdoorsman.When I am not in front of a computer screen, I might be fishing, taking a long hike or camping in the woods.  I like burnt cheese on caseroles, hot sauce on pizza, and strong black coffee. I appeared on a TV show pilot and I can be linked to Kevin Bacon in six degrees. I am also a huge",
        para3:"Bears fan. I live in an 100+yr old house with my partner, Ning and our cat, Chingu2.",
        para4:"I have 20+ years of real-world experience in interactive design and developement. I've worked for large public companies and lean startups. My past projects include design and development in ecommerce, hospitality, education, entertainment, insurance, and emarketing.",
        para5:" I also used to own a sushi restaurant.",
      },
    },
    navs: [
      { label: "home", id: "nav_home", link: "/home" },
      { label: "about", id: "nav_about", link: "/about" },
      { label: "skills", id: "nav_skills", link: "/skills" },
      { label: "work", id: "nav_work", link: "/work" },
      { label: "contact", id: "nav_contact", link: "/contact" },
    ],
    skills: [
      {
        title: "ux",
        run: "SEE SPOT RUN",
        list: [
          "usability testing",
          "heuristic evaluations",
          "contextual inquiry",
          "html protos/invision protos",
          "card sorts",
          "white boards/sketch concepts",
          "surveys",
          "personas",
          "photoshop/illustrator/sketch",
          "user-flows/user-stories",
          "wireframes",
          "css animations",
        ],
      },
      {
        title: "dev",
        run: "C://SPOT/RUN",
        list: [
          "html/css/less/scss",
          "vanilla javascript/vanilla apis",
          "vue.JS/vuex",
          "NODE/Express",
          "D3.JS",
          "Python/DJango",
          "php/Laravel",
          "bootstrap/foundation",
          "jquery/jqueryUI/jqMobile",
          "backbone.JS/angular1.JS",
          "mustacheJS/EJS",
          "responsive/mobile"
        ],
      }
      
    ],
    skillProcess: {
      process: [
        {
          name: "discover",
          desc:
            "I ask questions. I listen. I ask more questions. I step back. I listen some more.",
        },
        {
          name: "define",
          desc:
            "I set goals and limitations. I step back. I eliminate noise and items out of scope.",
        },
        {
          name: "design",
          desc:
            "I translate ideas into visual designs and working prototypes. I gather feedback. I step back. I improve my designs.",
        },
        { name: "develop", desc: "I apply code to solve specific problems. I gather feedback. I step back. I improve my code." },
        {
          name: "refine",
          desc:
            "I fill in the gaps and remove the clutter. I gather feedback. I step back. I improve my product, my process, and myself",
        },
      ],
    },
    portfolio: "",
    workPortfolio: [
      {
        itmIndex: 1,
        title: "work 1",
        img: require("@/assets/images/portfolio/port4.png"),
        mobile:require("@/assets/images/portfolio/port4-mobile.png"),
        load: require("@/assets/images/portfolio/port4.png"),
        activeStatus: true,
        aside: { title: "Iguana Perdida Hostel", 
        desc:"Iguana Perdida is a small family-owned hostel/hotel on the banks of  beautiful Lake Atitlan in Guatemala This consumer-facing web redesign reflects the boutique hostel's unique character and branding. The improved booking functionality and intuitive navigation enhances the overall user experience.", 
        link: "https://www.sonnykoh.com/iguana",
        gitlink: "https://github.com/kohproject/Iguana-Perdida-Hostel",
        techdesc:"This project utilized NODE/EXPRESS to store/display/edit reservations"},
        techStack: ["vue","scss", "node", "express", "love"],
      },
      {
        itmIndex: 2,
        title: "work 2",
        img: require("@/assets/images/portfolio/port2.png"),
        mobile:require("@/assets/images/portfolio/port2-mobile.png"),
        load: require("@/assets/images/portfolio/port4.png"),
        activeStatus: false,
        aside: { title: "Thai Order App", 
        desc:"Few restaurant owners can survive without the consumer convenience of online ordering Apps. Being keenly aware of the grip online order App companies have on small business owners, I wanted to create an online order app to learn the complexity of developing an online ordering food system. I quickly learned the business requirements are far more sophisticated than the simple happy path of a single customer order.", 
        link: "https://www.sonnykoh.com/thaiorder",
        gitlink: "https://github.com/kohproject/Thai-Order-App",
        techdesc:"This application utilized NODE/EXPRESS to manage data and handle user sessions."},
        techStack: ["vue","scss", "express", "node", "love"],
      },
      {
        itmIndex: 3,
        title: "work 3",
        img: require("@/assets/images/portfolio/port3.png"),
        mobile:require("@/assets/images/portfolio/port3-mobile.png"),
        load: require("@/assets/images/portfolio/port4.png"),
        activeStatus: false,
        aside: { title: "The Great Burger Divide", 
        desc:"As someone who used to live in Los Angeles, I really miss the infamous \"In&Out burger.\" I also get cravings for the less than famous \"Rubios\" fish tacos.  My friend told me he had the same issue with  \"Bojangles\" fried chicken that are only available in the SouthEast. I created this website for users to check the availability of their favorite fastfood outlet on an interactive map.", 
        link: "https://www.sonnykoh.com/greatburgerdivide",
        gitlink: "https://github.com/kohproject/Great-Burger-Divide",
        techdesc:"This project utilized the D3.js library with it's inherit D3 usa map API"},
        techStack: ["js", "scss", "d3", "love"],
      },
      {
        itmIndex: 4,
        title: "work 4",
        img: require("@/assets/images/portfolio/port1.png"),
        mobile:require("@/assets/images/portfolio/port1-mobile.png"),
        load: require("@/assets/images/portfolio/port1.png"),
        activeStatus: false,
        aside: { title: "KyuSsam Restaurant",
        desc:"As I mention in the 'About' section of this website, I used to own a restaurant. On a tight launch schedule, I put up this website where users could find us and order online using Grubhubs integration API.",
        link: "https://www.sonnykoh.com/kyussam",
        gitlink: "https://github.com/kohproject/KyuSsam-Restaurant",
        techdesc:"This project utilized php/phpAdmin/mysql to manage the food/desciption/pricing data"},
        techStack: ["scss", "jquery", "php", "phpAdmin", "love"],
      },
      {
        itmIndex: 5,
        title: "work 5",
        img: require("@/assets/images/portfolio/port5.png"),
        mobile:require("@/assets/images/portfolio/port5-mobile.png"),
        load: require("@/assets/images/portfolio/port1.png"),
        activeStatus: false,
        aside: { title: "Lawyer or Dropout", 
        desc:"The great majority of people I admire have either dropped out of college or have previously practiced law. I have heard many stories of lawyers leaving successful careers to pursue a true passion. I'm also amazed at the number of people that were able to achieve great success in a variety of fields without a college degree. I made this simple intuitive game to showcase the many people that fall into either bins.",
        link: "https://www.sonnykoh.com/lawyerdropout",
        gitlink: "https://github.com/kohproject/lawyer-or-dropout",
        techdesc:"This project utilized NODE/EXPRESS to manage the data content"},
        techStack: ["vue", "scss", "node", "express", "love"],
      },
      {
        itmIndex: 6,
        title: "work 6",
        img: require("@/assets/images/portfolio/port6.png"),
        mobile:require("@/assets/images/portfolio/port6-mobile.png"),
        load: require("@/assets/images/portfolio/port4.png"),
        activeStatus: false,
        aside: { title: "The KohProject",
        desc:"The idea for theKohProject came after I did a google search to see how many \"koh.com\" domain names were registered. I was surprised at the number of entries and variety of ethnicities and professions represented. I create this website as a hub for everyone in the world with the \"koh.com\" domain name.", 
        link: "https://www.sonnykoh.com/kohproject",
        gitlink: "https://github.com/kohproject/kohproject",
        techdesc:"This project utilizes python/django to manage the data and vanilla js to handle the complex filter capabilities on the frontend"},
        techStack: ["js", "scss", "python", "django", "love"],
      },
      {
        itmIndex: 7,
        title: "work 7",
        img: require("@/assets/images/portfolio/port7.gif"),
        load: require("@/assets/images/portfolio/port7.gif"),
        activeStatus: false,
        aside: { title: "CodePen Sandbox",
        desc:"CodePen is an awesome online application that allow users to develop and test UI concepts. It provides developers a place to store predefined widgets, copy and paste code, and share code snipets with others. These are just some of thes samples that I created to be used as reference for future projects.", 
        link: "https://codepen.io/sonnykoh",
        gitlink: "https://github.com/kohproject/todosAngular",
        techdesc:""},
        techStack: ["js", "scss","love"],
        sandbox:[{stitle:'BillBoard Flip Animation', slink:'https://codepen.io/sonnykoh/pen/JjEVMGZ'},
                {stitle:'Nav Bar with Following Arrow', slink:'https://codepen.io/sonnykoh/pen/QWJVvOP'},
                {stitle:'3D-FlipCard WWE-vs-WWF', slink:'https://codepen.io/sonnykoh/pen/rNQZmpx'},
                {stitle:'Loading Status GUI Bar', slink:'https://codepen.io/sonnykoh/pen/GRwXmOd'},
                {stitle:'Water Wave Text Effect', slink:'https://codepen.io/sonnykoh/pen/vYQzmpd'},
                {stitle:'Custom Drop- Down with Icons', slink:'https://codepen.io/sonnykoh/pen/wvQEdpR'},
                {stitle:'Bouncey Modal Effect', slink:'https://codepen.io/sonnykoh/pen/yLQxbvL'},
                {stitle:'Input-file CSS Stylize', slink:'https://codepen.io/sonnykoh/pen/zYMJwRB'},
                {stitle:'Password Strength Widget', slink:'https://codepen.io/sonnykoh/pen/mdQGmMx'},
                {stitle:'D3 Hover Donut Chart', slink:'https://codepen.io/sonnykoh/pen/jOZROXb'},
                {stitle:'Tooltip Bubble No-Javascript', slink:'https://codepen.io/sonnykoh/pen/jOQvmZj'},
                {stitle:'Angular Todo List Case Study', slink:'https://codepen.io/sonnykoh/pen/XWyPREd'}]
      }
    ],
    workIndex: 1,
    workData: {
      clients: [
        { name: "Second City", industry: "Entertainment", link: "" },
        { name: "BroadcastDVD", industry: "Entertainment", link: "" },
        { name: "NextTV", industry: "", link: "" },
        { name: "Answer Financial", industry: "", link: "" },
        { name: "USC", industry: "Entertainmen", link: "" },
        { name: "PersonalizationMall", industry: "Ecommerce", link: "" },
        { name: "Bed Bath&Beyond", industry: "Ecommerce", link: "" },
        { name: "FreeOsk", industry: "Ecommerce", link: "" },
        { name: "Navy Inns&Suites", industry: "Hospitality", link: "" },
        { name: "Jumierah International", industry: "Hospitality", link: "" },
        { name: "KaiTair", industry: "Hospitality", link: "" },
        { name: "Underwriters Laboratories", industry: "Technology", link: "" },
        { name: "Microsoft", industry: "Technology", link: "" },
        { name: "Manatt", industry: "Law", link: "" },
        { name: "LaTimes", industry: "Media", link: "" },
      ],
    },
    myData: [],
    AboutData: {
      topfive: [
        {
          type: "chicago eats",
          scaled: false,
          list: [
            {
              name: "Top Five Ramen",
              type: "ramen hole in the wall",
              link: "http://highfiveramen.com",
            },
            {
              name: "Xoco",
              type: "fast food mexican caldo",
              link: "https://www.rickbayless.com/restaurants/xoco/",
            },
            {
              name: "Honky Tonk Cafe",
              type: "bbq",
              link: "https://honkytonkbbq.com",
            },
            {
              name: "ChosunOk",
              type: "long time korean",
              link: "http://chosunokrestaurant.com",
            },
            {
              name: "Feed",
              type: "southern comfort",
              link: "https://feed-american-restaurant.business.site/",
            },
          ],
        },
        {
          type: "la eats",
          scaled: false,
          list: [
            {
              name: "The Prince",
              link:
                "https://la.curbed.com/2017/7/21/15931732/the-prince-restaurant-history-mad-men-chinatown",
            },
            { name: "Fathers Office", link: "https://fathersoffice.com" },
            { name: "Red Lion", link: "https://www.redliontavern.net" },
            { name: "Sake House Miro", link: "https://www.sakehousemiro.com" },
            { name: "HMS Bounty", link: "http://www.thehmsbounty.com" },
          ],
        },
        {
          type: "books",
          scaled: false,
          list: [
            {
              name: "the kiterunner",
              link:"https://www.amazon.com/Kite-Runner-Khaled-Hosseini/dp/159463193X",
            },
            {
              name: "pachinko",
              link: "https://www.amazon.com/Pachinko-National-Book-Award-Finalist/dp/1455563935",
            },
            {
              name: "the intrepreter",
              link:"https://www.amazon.com/Interpreter-Novel-Suki-Kim/dp/0312422245",
            },
            {
              name: "malcolmX",
              link:"https://www.amazon.com/Autobiography-Malcolm-Told-Alex-Haley/dp/0345350685",
            },
            {
              name: "beyond the sky...",
              link:"https://www.amazon.com/Beyond-Sky-Earth-Journey-Bhutan/dp/157322815X/",
            },
          ],
        },
        {
          type: "tv",
          scaled: false,
          list: [
            {
              name: "Parts Unknown",
              link: "https://www.cnn.com/shows/anthony-bourdain-parts-unknown/",
            },
            { name: "Sopranos", link: "https://www.imdb.com/title/tt0141842/" },
            {
              name: "Northern Exposure",
              link: "https://www.imdb.com/title/tt0098878/",
            },
            {
              name: "Midnight Diner",
              link: "https://www.imdb.com/title/tt6150576/",
            },
            {
              name: "Silicon Valley",
              link: "https://www.hbo.com/silicon-valley",
            },
          ],
        },
        {
          type: "movies",
          scaled: false,
          list: [
            {
              name: "Godfather Part II",
              link: "https://www.imdb.com/title/tt0071562/",
            },
            {
              name: "High Fidelity",
              link: "https://www.imdb.com/title/tt0146882/",
            },
            {
              name: "The Commitments",
              link: "https://www.imdb.com/title/tt0101605/",
            },
            {
              name: "The Crying Games",
              link: "https://www.imdb.com/title/tt0104036/",
            },
            {
              name: "Hoop Dreams",
              link: "https://www.imdb.com/title/tt0110057/",
            },
          ],
        },
        {
          type: "albums",
          scaled: false,
          list: [
            {
              name: "Purple Rain",
              artist: "Price",
              link:
                "https://www.amazon.com/Purple-Rain-Deluxe-2CD-Prince/dp/B071DQNJVF/",
            },
            {
              name: "Nevermind",
              artist: "Nirvana",
              link: "https://www.amazon.com/Nevermind-Nirvana/dp/B0057GYO9K/",
            },
            {
              name: "Disintegration",
              artist: "The Cure",
              link:
                "https://www.amazon.com/Cure-Disintegration-CURE/dp/B003Z0FSDQ/",
            },
            {
              name: "Play",
              artist: "Moby",
              link: "https://www.amazon.com/Moby-Play-MOBY/dp/B00004WS86/",
            },
            {
              name: "Twitch",
              artist: "Ministry",
              link: "https://www.amazon.com/Twitch-MINISTRY/dp/B00KQ4M7PQ/",
            },
          ],
        },
        // },
        // { type:"websites",
        //     list:[
        //     {name:"target.com"},
        //     {name:"nike.com"},
        //     {name:"tripadvisor.com"},
        //     {name:"playground.wonderlandams.com"},
        //     {name:"airbnb.com"},
        //   ]
        // }
      ],
      edu: [
        {
          type: "graduate",
          name: "depaul university",
          link: "https://www.depaul.edu",
        },
        {
          type: "undergraduate",
          name: "university of illinois at chicago",
          link: "https://www.uic.edu",
        },
        {
          type: "highschool",
          name: "lanetech prep",
          link: "https://lanetech.org",
        }
      ],
      org: [
        {
          type: "javascript user group",
          name: "Northwest Javascript",
          link: "https://www.meetup.com/Northwest-Chicago-JavaScript/",
        },
        {
          type: "vuejs user group",
          name: "Chicago VueJS",
          link: "https://www.meetup.com/chicago-vue-js/",
        },
        {
          type: "national association of asian american professionals",
          name: "NAAAP",
          link: "https://www.naaapchicago.org/",
        },
        {
          type: "honor society for computing",
          name: "Upsilon Pi Epsilon",
          link: "https://upe.acm.org/",
        },
        {
          type: "international scuba association",
          name: "PADI",
          link: "https://www.padi.com/",
        },
      ],
      quotes: [
        {
          name: "Galileo",
          theme: "focus",
          quote: "The sun does not revolve around the earth.",
        },
        {
          name: "Pablo Picasso",
          theme: "inspiration",
          quote: "A good artist borrows, a great artist steals!",
        },
        {
          name: "Albert Einstein",
          theme: "simplicity",
          quote:
            "Everything should be as simple as possible, but not any simpler.",
        },
        {
          name: "Isaac Newton",
          theme: "usability",
          quote:
            "I can calculate the motion of the heavens, but not the madness of men.",
        },
        {
          name: "Mike Tyson",
          theme: "execution",
          quote: "Everyone has a plan; then you get punched in the face.",
        }
      ],
    },
  },
  mutations: {
    SETWORKHOVER(state, payload) {
      (payload) ? state.$("list_work").classList.add('hover') : state.$("list_work").classList.remove('hover');
    },
    SETWORKITEM(state, payload) {
      state.workItem = state.workPortfolio.find((obj) => obj.itmIndex === payload);
    },
    SETMOBILEWIDTH(state,payload){
      state.pageWidth = payload;
    },
    SETBODYTYPE(state, payload) {
      state.type = payload;
      let $type = state.type.replace("/", "");
     
      state.$("body").className = $type;
    },
    SETACTIVESTATE(state, payload) {
      state.workPortfolio.forEach((itm) => {
        itm.activeStatus = (itm.itmIndex === payload) ? true : false;
      });
    },
    SETCODEPEN(state, payload) {
        state.isCodePen = payload;
    },
    SETISMOBILE(state, payload) {
      state.isMobile = payload
    },
    SCROLLWORKVIEW(state, payload) {
      state.workIndex = payload ? payload.target.getAttribute("data-key") : "work 1";
      state.workLoadingBool = true;

      let $num = parseInt(state.workIndex.replace("work", ""));

      this.commit("SETACTIVESTATE", $num);
      this.commit("SETWORKITEM", $num);

      setTimeout(() => {
        state.workLoadingBool = false;
      }, 500);
    },

    DISPLAYWORK(state, payload) {
      state.portfolio = payload;
      state.$("full").className = "full-container " + state.portfolio;
    },

    SCROLLINTOVIEW(state, payload) {
      let $type = payload.replace("/", "");
      let buffer = 40;

      switch ($type) {
        case "about":
          buffer = 260;
          break;
        case "skills":
          buffer = 180;
          break;
        case "contact":
          buffer = 150;
          break;
        case "work":
          if(state.isMobile) buffer = 0;
          state.workLoadingBool = false;
          this.commit("SETACTIVESTATE", 1);
          this.commit("SETWORKITEM", 1);
          this.commit("SETCODEPEN", false);
          break;
        case "home":
          state.switchMode = "switch-ux";
            state.$("app").classList.remove("switch-dev");
            state.$("app").classList.add("switch-ux");
            state.$("sect_home").classList.remove("switch-dev");
        break;
      }

      setTimeout(function() {
        window.scroll({ top: document.querySelector(".sect__" + $type).offsetTop - buffer, 
          left: 0, behavior: "smooth" });
      }, 100);
    }
  },
  actions: {
    setShadowBlock({ commit }, payload) {
   
      commit("SETWORKHOVER", payload);
    },
    setActionScroll({ commit }, payload) {
      commit("SCROLLINTOVIEW", payload);
    },
    scrollWorkBlock({ commit }, payload) {
      commit("SCROLLWORKVIEW", payload);
    },
    setMobileNav({commit},payload){
      commit("SETISMOBILE", payload);
    },
    sentEmail(payload) {
      console.log(payload);
    },
    setStoreScreenWidth({commit},payload){
      commit("SETMOBILEWIDTH", payload);
    }
  },
  getters: {
    GETNAVS(state) {
      return state.navs;
    },
    GETMODE(state) {
      return state.switchMode;
    },
    // getSkills(state) {
    //   return state.skills;
    // },
    GETABOUT(state) {
      return state.about;
    },
    // GETTEST(state) {
    //   return state.test;
    // },
    GETEDU(state) {
      return state.AboutData.edu;
    },
    GETORG(state) {
      return state.AboutData.org;
    },
    GETQUOTES(state) {
      return state.AboutData.quotes;
    },
    GETPAGEWIDTH(state){
      return state.pageWidth;
    },
    GETCODEPEN(state){
      return state.isCodePen;
    },
    GETTOPFIVE(state) {
      return state.AboutData.topfive;
    },
    GETSKILLPROCESS(state) {
      return state.skillProcess.process;
    },
    GETSKILLSET(state) {
      return state.skills;
    },
    GETWORK(state) {
      return state.workPortfolio;
    },
    GETWORKLOADING(state) {
      return state.workLoadingBool;
    },
    GETWORKITEM(state) {
      return state.workItem;
    },
    GETWORKCLASS(state) {
      return state.workItem.title.replace(' ','');
    },
    GETWORKTECH(state) {
      return state.workItem.techStack;
    },
    GETISMOBILE(state){
      return state.isMobile;
    }
  
  },
  modules: {},
});
