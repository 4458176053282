<template>
  <nav :class="{'sho': shoBool }">
    <ul id="list__nav">
      <li :key="itm.label" v-for="(itm, index) in navs" :data-index="index">
        <a
          :class="['link__nav', { 'active': getNavBool(itm.id) }]"
          :id="itm.id"
          href="javascript:void(0)"
          @click="setNavType(itm.link, itm.id)"
          >{{ itm.label }}</a
        >
      </li>
    </ul>
  </nav>
  <a @click="toggleMobileMenu()" :class="['hamburger', { 'close': shoBool }]"></a>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "nav",
  data() {
    return {
      shoBool: false,
      activeId: "nav_home",
    };
  },
  methods: {
    ...mapActions({ setScroll: "setActionScroll", mobileView: "setMobileNav", setScreenWidth: "setStoreScreenWidth" }),

    toggleMobileMenu(bool) {
      this.shoBool = bool ? bool : !this.shoBool;
      this.$store.commit("SETBODYTYPE", this.activeId.replace("nav_", ""));
      return false;
    },
    getWidth() {
      return window.innerWidth > 0 ? window.innerWidth : screen.width;
    },
    getNavBool(_id) {
      return _id === this.activeId ? true : false;
    },
    setNavType(type, id) {
      this.activeId = id;
      this.mobileView(this.getWidth() < 899 ? true : false);
      if (this.isMobile) this.toggleMobileMenu(false);
      this.setScroll(type);
    },
  },
  computed: mapGetters({ navs: ["GETNAVS"], isMobile: ["GETISMOBILE"] }),
  mounted() {
    this.mobileView(this.getWidth() < 600 ? true : false);
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/global.scss";

$active: $home;

nav {
  background: #fff;
  height: 40px;
  width: 100%;
  max-width: calc(100vw - 240px);
  position: fixed;
  top: 0px;
  left: 235px;
  opacity: 0.9;
  display: flex;
  z-index: 102;
  * {
    z-index: 10;
  }
  p.txt__temperature {
    display: flex;
    justify-content: center;
    width: auto;
    padding: 12px 20px 0px 20px;
    white-space: nowrap;
    height: 40px;
    font-size: 12px;
    position: absolute;
    color: #333;
    top: 0px;
    right: 20px;
    span {
      padding-left: 10px;
    }
  }
  ul#list__nav {
    display: flex;
    min-width: 350px;
    margin-left: 25%;
    width: 100%;
    position: relative;
    opacity: 0;
    --floatBox: #777;
    --leftAfter: 40px;

    &:before {
      content: "";
      height: 32px;
      width: 100px;
      background: var(--floatBox);
      top: 4px;
      margin-left:-40px;
      left: var(--leftAfter, 40px);
      position: absolute;
      z-index: -1;
      transition: left 0.3s ease;
    }

    li {
      display: inline;
      margin-right: 4px;

      a {
        font-size: 18px;
        display: flex;
        text-decoration: none;
        justify-content: center;
        background: transparent;
        color: $grey666;
        margin-top: 3px;
        min-width: 100px;
        padding-top: 2px;
        height: 34px;
        position: relative;
        border-top: solid 1px transparent;
        --dotcolor: #ccc;
        &:hover {
          color: #111;
          cursor: pointer;
          border-top: solid 1px #ddd;
          background: #eee;
          transition: background-color 0.1s ease-in;
        }
      }
      a.link__nav:hover{
        cursor: pointer;
      }

    }
  }
}
a.hamburger {
  display: none;
  width: 32px;
  height: 36px;
  border-top: solid 6px #ccc;
  position: fixed;
  z-index: 9999999;
  mix-blend-mode: multiply;
  top: 7px;
  right: 8px;
  transform: scale(0.8);
  &:before,
  &:after {
    content: "";
    display: block;
    background: #ccc;
    width: 100%;
    height: 6px;
    left: 0px;
    position: absolute;
    transition: transform 0.2s ease;
    top: 4.5px;
  }
  &:after {
    top: 15px;
  }
}

a.hamburger.close {
  border-top: none;
  top: 10px;
  right: 4px;
  &:before,
  &:after {
    transform: rotate(44deg);
    top: 4.5px;
  }
  &:after {
    transform: rotate(-44deg);
  }
}
</style>
