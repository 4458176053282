<template>
  
    <div class="box__skill">
      <h3>
        {{ sk.title }} 
      </h3>
      <fieldset>
        <legend></legend>
      </fieldset>

      <ol>
        <li v-for="itm in sk.list" :key="itm.title">
          {{ itm }}
        </li>
      </ol>
    </div>
  
</template>

<script>
export default {
  props: ["sk"],
  name: "SkillBox"
};
</script>

<style lang="scss" scoped>

@import "../assets/scss/global.scss";
$skills: #ff154e;
$skills:#a0397b;
$skills:#35145b;
$skills:#204b70;
$legend:desaturate(lighten($skills,60%),40%);

.box__skill {
  width:400px;
  font-size: 30px;
  padding: 10px 20px 20px 0px;
  margin-bottom: 10px;
  margin-right: 10px;
  position: relative;
  background: $skills;
  border: solid 1px lighten($skills, 20%);

  fieldset {
    height: 60px;
    border: 0px;
    padding: 0px;
    max-width: 400px;
    width:100%;
    position: absolute;
    top: 100px;
    left: 197px;
    transform: translate(-50%, -50%);
    z-index: 99;
    overflow: visible;

    &:before,
    &:after {
      content: "";
      height: 64px;
      width: 84px;
      position: absolute;
      left: 0px;
      display: block;
      z-index: 999999;
      left: 0px;
      border-right: solid 1px #6626af;
      border-left: solid 1px #6626af;
      border-right:solid 1px #3780bf;  
      border-left:solid 1px #3780bf;
      background: #35145b;;
 background: #204b70;
    }
    &:before{
      border-left:none;
    }
    &:after {
      width: 86px;
      right: 0px;
      left: auto;
      border-right:none;
    }

    > legend {
      font: bold 22px courier;
      letter-spacing: -0.03em;
      padding-left: 20px;
      padding-top: 8px;
      width: 230px;
      text-align: center;
      overflow: visible;
      text-shadow: 0px 1px 0px #eee;
      height: 44px;
      text-indent: -30px;
      background: $legend;
      padding-bottom: 5px;
      color: #ff114f;
      display: block;
      top: 10px;
      left: 197px;
      
      transform: translateX(-50%);
      position: absolute;
      &:before,
      &:after {
        content: "";
        height: 64px;
        position: absolute;
        display: block;

        top: -20px;
        left: 2px;
        width: 19px;
        z-index: 999;
        background: url("../assets/images/legend-bg.png") no-repeat 0 0;
        background-size: 8px 100px;
      }
      &:after {
        left: auto;
        right: -2px;
        transform: rotateY(-180deg);
      }
    }
  }
  > h3 {
    display: flex;
    align-content: center;
    font-size: 50px;
    font-weight: normal;
    line-height: 60px;
    color: $white;
    padding-left: 75px;
    text-align: left;
    margin-bottom:20px;
    text-shadow: 1px 1px 1px darken($skills, 5%);
    span{text-shadow:none;font-size:15px;font-family:courier;padding-top:12px;
    padding-left:15px;display:inline-block; height:50px;}
  }
  > ol {
    width: 100%;
    position: relative;
    margin-top: 75px;
    margin-left: 20px;
    > li {
      display: block;
      height: auto;
      color: $white;
      font-size: 20px;
      margin-bottom: 4px !important;
      padding-left: 8% !important;
      text-align: left;
      white-space: nowrap;
      position: relative;
      text-transform: lowercase;
      // text-shadow: 0px 1px 1px darken($skills, 5%);
      &:before {
        content: "";
        padding: 2px 4px 3px 4px;
        min-width: 30px;
        display: block;
        text-align: center;
        border-radius: 4px;
        font: normal 11px arial;
        top: 50%;
        position: absolute;
        opacity:.9;
        left: -6%;
        color:lighten($skills, 60%);
        background: darken($skills, 6%);
        transform: translateY(-50%);
      }
    }
  }
}
</style>