<template>
  <div class="wrapper__edu mt20">
    <div class="box">
      <h4>{{ title2 }}</h4>
      <ul>
        <li v-for="itm in org" :key="itm.name">
          <dt>
            <a :href="itm.link" rel="nofollow" target="_blank">{{itm.name}}</a>
          </dt>
          <dd>{{ itm.type }}</dd>
        </li>
      </ul>
    </div>
    <div class="box">
      <h4>{{ title1 }}</h4>
      <ul>
        <li v-for="itm in edu" :key="itm.name">
          <dt>
            <a :href="itm.link" rel="nofollow" target="_blank">{{
              itm.name
            }}</a>
          </dt>
          <dd>{{ itm.type }}</dd>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EDU",
  data() {
    return {
      title1: "Education",
      title2: "Organizations",
    };
  },
  computed: mapGetters({ edu: ["GETEDU"], org: ["GETORG"] }),
};
</script>
<style lang="scss" scoped>
@import "../assets/scss/global.scss";
.wrapper__edu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top:40px;
  justify-content: space-between;
  .box {
    border: solid 1px lighten($aboutAside, 20%);
    margin-bottom: 40px;
    box-shadow: 2px 5px 12px #990000;
    padding: 15px 20px 20px 30px;
    position: relative;
    width: 48%;
    

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: $aboutAside;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -1;
      opacity: 0.1;
    }
    h4 {
      font-size: 1.5rem;
      margin-bottom: 15px;
      opacity: 0.9;
      font-weight: 400;
    }
    > ul > li {
      dt {
        font-weight: bold;
        font-size: 15px;
        line-height: 100%;
        margin-bottom: 0px;
        letter-spacing: 0.07em;
        a {
          color: $white;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
      dd {
        font:normal 12px arial;
        margin-bottom: 10px;
        color: lighten($skills, 35%);
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper__edu {
    .box {width:99%;margin-bottom: 0px;}
    .box:nth-child(1){
      margin-bottom:30px
    }
  }
}
</style>