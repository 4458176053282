<template>
  <section class="sect sect__skills" data-type="skills">
    <h2>{{ title }}</h2>
    <div class="flex">
     <div class="wrapper__skills buffer-white">
        <ul class="flex">
          <li v-for="itm in skills" :key="itm.title">
          <SkillBox
            :title="itm.title"
            :sk="itm"
          />
          </li>
        </ul>
        </div>
      </div>
       <SkillProcess />
    <em></em>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import SkillBox from "./SkillBox.vue";
import SkillProcess from "./SkillProcess.vue";
export default {
  name: "Skills",
  data(){
    return {
      title:"Skills"
    }
  },
  // data() {
  //   return {
  //     title: "Skills",
      // skills: [
      //   {
      //     title: "dev",
      //     run: "C://SPOT/RUN",
      //     list: [
      //       "html/css/less/scss",
      //       "responsive/mobile",
      //       "bootstrap/foundation",
      //       "vanilla javascript",
      //       "backboneJS/angularJS",
      //       "mustacheJS/EJS",
      //       "vueJS/Vuex",
      //       "D3JS",

      //       "jquery/jqueryUI/jqueryMobile",
      //       "NODE/ExpressJS",
      //       "php/phpAdmin",
      //     ],
      //   },

      //   {
      //     title: "ux",
      //     run: "SEE SPOT RUN",
      //     list: [
      //       "sketch concepts",
      //       "usability testing",
      //       "html prototypes",
      //       "card sorts",
      //       "white boards",
      //       "surveys",
      //       "personas",
      //       "photoshop/illustrator/sketch",
      //       "userflows",
      //       "css animations",
      //     ],
      //   }
      // ],
    //}
  //},
  components: { SkillBox, SkillProcess },
  computed:mapGetters({skills:['GETSKILLSET']})
};
</script>

<style lang="scss">
@import "../assets/scss/global.scss";
$skills:#a0397b;
$skills:#35145b;
$skills:#204b70;

section.sect__skills {
  position: relative;
  min-height: 120vh;
  height: auto;
  padding: 50px 0px 20px 0px;
  overflow: visible;
  background: $skills;
  background:#444;
  color: #fff;
  opacity: 1;
    &:after {
    content: "";
    height: 40px;
    width: 0%;
    background: $skills;
    position: absolute;
    z-index: 1;
    opacity: 1;
    top: 131px;
    left: -100%;
    z-index: 0;
  }
  // > em {
  //   width: 135px;
  //   height: 105px;
  //   position: absolute;
  //   left: -200px;
  //   top: 200px;
  //   background-image: url(../assets/images/dog.svg);
  //   background-size: 100%;
  //   opacity: 0.2;
  //   display: block;
  //   &:after {
  //     content: "</>";
  //     position: absolute;
  //     transform: rotate(-360deg);
  //     top: -100px;
  //     left: 300px;
  //     color: #333;
  //     font: bold 20px verdana;
  //   }
  // }

  .wrapper__skills{
      display: flex;
      max-width: 92vw;
      padding-left:15%;
  }

  h2 {
    @include minW;
    width: 90%;
    color: lighten($skills,20%);
    min-width: 320px;
    text-align: left;
    position: absolute;
    top: -120px;
    font-size: 90px;
    left: 220px;
    z-index: 2;
  }

  ul.flex {
    @include centered;
    justify-content: space-between;
    width: 100%;

    > li {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 320px;
      max-width: 400px;
      min-height:580px;
      margin-right: 80px;
      justify-content: flex-start;
  
      .box__skill {
        > ol > li:before {
          content: "dev";
        }
      }
      &:first-of-type {
        .box__skill {
          > ol > li:before {
            content: "ux";
          }
        }
      }
    }
  }
}
</style>
