<template>
<section class="sect sect__work1">
        <div class="flex">
            <div class="flex fx1 flex-column max212">

            </div>
            <div class="flex fx3 flex-column">

               <div class="wrapper__portfolio">
                   <h3>01. Presentation</h3>
                   <ul class="list__portfolio">
                    <li><a href="#" class="active">01</a></li>
                    <li><a href="#">02</a></li>
                    <li><a href="#">03</a></li>
                    <li><a href="#">04</a></li>
                 </ul>
               </div>
            </div>

        </div>
        <aside>
            <h1>
                <em class="white"></em>
            </h1>
            <h2><em></em>back</h2>
            <em></em>
          </aside>
    </section>
</template>
<script>
export default{
    name:'Work1'
 }
</script>

<style lang="scss">

$sonnyGold:#f59400;

section.sect__work1 {
    position: relative;

    aside {
        position: absolute;
        top: 0px;
        width: 212px;
        min-height: 100vh;
        left: 0px;
        background: #fff;
        background: linear-gradient(to right, #fff, #fff 0%, #fff 96%, #333 100%);
        h1 {
            display: block;
            position: fixed;
            left: 32px;
            top: 0px;
            background: $sonnyGold;
            background: linear-gradient(to right, $sonnyGold 0%, $sonnyGold 96%, darken($sonnyGold, 24%) 100%);
            margin: 0px;
            width: 180px;
            height: 40px;
            font-weight: normal;
            
            em.white {
                position: absolute;
                display: block;
                left: -32px;
                width: 32px;
                height: 40px;
                background: $sonnyGold;
           
                &:before,
                &:after {
                    content: '';
                    width: 26px;
                    height: 26px;
                    border: solid 4px #fff;
                    border-radius: 28px;
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
        
            }
        
            &:after {
                content: "sonnykoh";
                position: absolute;
                font-size: 25px;
                display: flex;
                height: 40px;
                width: 180px;
                align-items: center;
                padding-left: 18px;
                color: #fff;
                
            }
        }
        
        h2 {
            display: block;
            position: fixed;
            left: 32px;
            top: 40px;
            background: #333;
            margin: 0px;
            width: 180px;
            height: 40px;
            em{
                display: block;
                height: 40px;
                position:absolute;
                left:-32px;
                width:32px;background:#333;
                &:after,
                &:before{
                    content:'';
                    display:block;
                    width:30px;
                    height:2px;
                    background:#fff;
                    top:calc(50% - 1px);
                }
            }
        }
    }
    .wrapper__portfolio {
        width:100%;
        max-width:900px;
        min-height: 100vh;
        background: #cc0000;
        overflow:hidden;
        position: relative;
        float: left;

        h3 {
            width: 100%;
            height: 40px;
            // background: pink;
            margin: 0px;
            display: block;
        }

        ul.list__portfolio {
        margin: 0px;
        height: 40px;
        background: green;
        width:auto;
        display:block;
        float:right;
        position:relative;
        margin:0px;
        padding:0px;
        flex-direction: flex-end;
        li {
            display: inline;
            position:relative;
            a{
                display:block;
                height:40px;
                width:50px;
                margin-right:10px;
                float:left;
                background:blue;
            }
            a.active{
                background:yellow;
            }
        }
    }
}
}

</style>

