<template>
  <div class="full-container" id="full">
    <Nav />
    <Home :vmode="switchMode" v-on:clicked="setMode" />
    <section class="sect__shadow sect" data-type="about"></section>
    <About />
    <section class="sect__shadow bg-white sect" data-type="skills"></section>
    <Skills />
    <section class="sect__shadow bg-white"></section>
    <Work />
    <section class="sect__shadow bg-white"></section>
    <Contact />
  </div>
  <Footer />
  <Title />

  <div class="wrapper__header">
    <h1>
      <span>{{ title }}</span>
    </h1>
  </div>

  <router-view />
</template>

<script>
import { mapGetters } from "vuex";

// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import Title from "@/components/Title.vue";
import Footer from "@/components/Footer.vue";
import Home from "@/components/Home.vue";
import About from "@/components/About.vue";
import Skills from "@/components/Skills.vue";
import Work from "@/components/Work.vue";
import Contact from "@/components/Contact.vue";

export default {
  name: "App",
  computed: mapGetters({ switchMode: ["GETMODE"] }),
  data() {
    return {
      title: "sonnykoh",
    };
  },

  components: {
    Nav,
    Title,
    Footer,
    Home,
    About,
    Work,
    Skills,
    Contact,
  },

  mounted() {
    this.setObserver();
  },

  methods: {
    setSection(_type, _isMobile) {
      let $ = (id) => document.getElementById(id);
      let $h3 = $("h3_contact");

      switch (_type) {
        case "home":
          if ($("app").classList.contains("switch-dev")) {
            $("chx_slider").click();
          }
          break;
        case "work":
          if ($("link_toggle-view").dataset.mode === "desktop") {
            $("link_toggle-view").click();
            $("link__live").className = "link__live-link";
          }
          if (
            $("list_work")
              .getElementsByTagName("aside")[0]
              .classList.contains("expanded")
          ) {
            $("list_work").querySelector(".link__expand").click();
          }
          break;
        case "contact":
          $h3.className = "head__contact";
          $h3.innerHTML = "Hello";
          var liErr = document.querySelectorAll("li.li-form__validate.err");

          if (liErr.length > 0) {
            liErr.forEach((itm) => {
              itm.className = "li-form__validate";
            });
          }
          break;
      }

      if (_isMobile) {
        this.setMobileNav($("list__nav"), _type);
      }
    },
    //set active state for mobile nav
    setMobileNav(_list, _type) {
      _list.querySelectorAll(".link__nav").forEach((itm) => {
        itm.className =
          itm.innerText == _type ? "link__nav active" : "link__nav";
      });
    },
    setMode(_mode) {
      this.switchmode = _mode;
    },

    setObserver() {
      const _self = this;
      const $ = (id) => document.getElementById(id);
      const isMobile = window.innerWidth < 900 ? true : false;
      const $body = $("body");

      let callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            if (entry.isIntersecting) {
              let $type = entry.target.dataset.type;
              if ($type !== undefined) {
                $body.className = $type;
                _self.setSection($type, isMobile);
              } else {
                $body.className = "home";
                _self.setSection("home", isMobile);
              }
            }
          }
        });
      };

      const $app = $("app");

      let options = {
        root: null,
        rootMargin: "1000px",
        threshold: 1.0,
      };

      if (isMobile) options.rootMargin = "700px";

      let observer = new IntersectionObserver(callback, options);
      let targs = document.querySelectorAll(".sect");

      targs.forEach((targ) => observer.observe(targ));
      const $param = window.location.search;

      if ($param) {
        setTimeout(() => {
          $app.className = "dot1 dot2 init";
          //document.getElementById('list__nav').style.setProperty('--leftAfter','352px');
          $("nav_work").click();
        }, 100);
      } else {
        const $prom = new Promise((res) => {
          setTimeout(() => {
            $app.classList.add("dot1");
            res(true);
          }, 500);
        });

        $prom
          .then((bool) => {
            if (bool) {
              setTimeout(() => {
                $app.classList.add("init");
                $body.className = "home";
              }, 500);
            }
          })
          .then(function () {
            setTimeout(() => {
              $app.classList.add("dog");
            }, 300);
          });
      }
    },
  },
};
</script>


<style lang="scss">
@import "../assets/scss/global.scss";
.hid {
  display: none;
}
.full-container {
  display: block;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.wrapper__header {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  z-index: 999999;
  > h1 {
    position: sticky;
    color: #ccc;
    letter-spacing: 0.02em;
    z-index: 999999;
    top: 0px;
    font-size: 27px;
    font-weight: normal;
    width: 240px;
    height: 40px;
    color: #fff;
    background: url(../assets/images/skLogo.svg) no-repeat right top #ffb916;
    margin-top: 40px;
    margin-left: 0px;
    span {
      display: block;
      height: 40px;
      width: 170px;
      padding-left: 30px;
      position: absolute;
      left: 70px;
      top: 0px;
      color: #ffb916;
      text-align: left;
      background: #fff;
    }
    &:before {
      content: "";
      width: 240px;
      height: 40px;
      background: #fff;
      top: -40px;
      right: 0px;
      left: auto;
      display: block;
      position: absolute;
    }
    &:after {
      content: "";
      height: 40px;
      display: block;
      width: 15px;
      background: linear-gradient(
        to right,
        #ccc 0%,
        #eee 30%,
        #eee 90%,
        #ddd 91%,
        #ddd 100%
      );
      background: linear-gradient(
        to right,
        #fff 0%,
        #fff 95%,
        #eee 96%,
        #eee 100%
      );
      left: 58px;
      top: 0px;
      position: absolute;
    }
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  z-index: 0;
}
</style>
