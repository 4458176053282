<template>
  <div class="wrapper__quotes">
    <ul>
      <li v-for="(itm, index) in quotes" :key="itm.theme">
        <h5 :class="{ mtop10: index === 0 }">{{ itm.theme }}</h5>
        <cite>{{ itm.quote }}</cite
        ><span>{{ itm.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Quotes",
  computed: mapGetters({ quotes: ["GETQUOTES"] })
};
</script>
<style lang="scss" scoped>
.wrapper__quotes {
  overflow: hidden;
  > ul {
    > li {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      h5 {
        margin: 25px 0px 4px 0px;
        line-height: 1.3rem;
        opacity: 0.6;
      }
      h5.mtop10 {
        margin-top: 10px;
      }
      cite {
        font: italic 17px "Times New Roman";
        margin: 2px 0px 5px 0px;
      }
      > span {
        font-size: 17px;
        font: italic 20px "Times New Roman";

        &:before {
          content: "~ ";
        }
      }
    }
  }
}
</style>