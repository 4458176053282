<template>
    <footer>
        <h2>&copy; copyright 2022</h2>
        <p>This website was made with <em class="icon-vue"></em> Vue, <em class="icon-vuex"></em>Vuex, <em class="icon-sass"></em>SASS, <em class="icon-php"></em>PHP, and <em class="icon-love"></em>Love</p>
        <p><a href="https://www.youtube.com/watch?v=elh2SZ8u_yY" target="_blank" rel="nofollow" class="link__happy">happy trails</a>...</p>
        <div class="hid">
          <img src="http://sonnykoh.com/img/port4-mobile.32725496.png" class="hid"/>
     
        </div>
      </footer>
</template>
<script>

export default {
  name: 'Footer'
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/global.scss";
  footer{
    width:100%;
    max-width:100vw;
    height:25vh;
    min-height:25vh;
    overflow:hidden;
    background:$footer;
    padding:20px 0px 20px 100px;
    border:solid 10px darken($footer,3%);
    color:lighten($footer,50%);
    a.link__happy{
      padding-bottom:0px;
      border-bottom:solid 1px #999;
      color:#ccc;
    }
    h2{
      color:#fff;
      margin:2px 2px 6px 2px;
      opacity:.4;
      font-size:18px;
      text-align:center;
    }
  }
</style>
