<template>
  <section
    :class="['sect sect__work', { 'mobile-expanded': expandBool }]"
    data-type="work">
    <div class="wrapper__work-header">
      <h2>work</h2>
      <ol class="list__itm-work">
        <li v-for="(itm, index) in work" :key="itm.title">
          <a
            href="javascript:void(0)"
            @click="setWorkBlock($event, index, itm.title)"
            :data-key="itm.title" :data-num="index+1"
            :class="['link__itm-work', { 'active': itm.activeStatus }]"></a>
        </li>
      </ol>
    </div>
    <div
      id="list_work"
      :class="['wrapper__work buffer-white',
        { mobile: $store.state.isMobile },
        { loading: loadWorkBool }, workCl]">
      <div class="wrap__work-outer">
        <Aside v-on:clicked="toggleExpand"  v-on:workBlockState="setBlockState" :isPen="isCodePen"/>
        <div class="block__work">
          <ul
            id="list_skeleton"
            :class="['list-skeleton',{ skeleton: $store.state.workLoadingBool }]">
            <li
              v-for="(itm,index) in work"
              :key="itm.title"
              @click="$store.commit('DISPLAYWORK', itm.aside.link)">
             
              <div v-if="itm.title =='work 7'" class="work-sandbox">
                <ol>
                  <li v-for="(jtm,index) in itm.sandbox" :key="jtm.stitle">
                    <a :href="jtm.slink" target="_blank"><span>{{index + 1}}.</span>
                      <h5>{{jtm.stitle}}<em></em></h5></a>
                  </li>
                </ol>
                <img :id="'img_'+index" 
                :src="itm.load" :data-src="itm.img" 
                :data-mobile="itm.mobile" class="img-port" />
              </div>
              <div v-if="itm.title !='work 7'" class="wrap__img-port">
                <img :id="'img_'+index" 
                :src="itm.load" :data-src="itm.img" 
                :data-mobile="itm.mobile" class="img-port" />
              </div>
            </li>
          </ul>

          <div v-if="hasMobileView"
            :class="['view__work-wrap',{ 'sho': hasMobileView, 'mobile': (workView !=='mobile') }]">
            <p>
              <a href="#" id="link_toggle-view" :data-mode="workView" @click="switchImgMode($event)"><em></em></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Aside from "./Work-Aside.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Work",
  data() {
    return {
      workNum: 0,
      expandBool: false,
      workView: "mobile",
      hasMobileView:true
    };
  },
  computed: mapGetters({
    work: ["GETWORK"],
    loadWorkBool: ["GETWORKLOADING"],
    workCl: ["GETWORKCLASS"],
    isCodePen:["GETCODEPEN"]
  }),
  created() {
    this.$store.commit("SETWORKITEM", 1);
    this.loadWorkBool = true;
  },
  methods: {
    ...mapActions({
      setScrollWorkBlock: "scrollWorkBlock",
      setBlockState: "setShadowBlock"
    }),
    setViewImg: function(imgtype){
      let $img = document.getElementById('img_'+this.workNum);
      $img.src = (imgtype ==='mobile') ? $img.dataset.src : $img.dataset.mobile;
    },
   
    toggleExpand: function (bool) {
      this.expandBool = bool;
    },
    switchImgMode: function(evt){
      evt.preventDefault();
      this.setViewImg(this.workView = (this.workView === "mobile") ? "desktop" : "mobile");
      return;
    },
  
    setWorkBlock: function (evt, _index, title) {
      if (evt.currentTarget.classList.contains("active")) return;
      this.setViewImg(this.workView='mobile');
      this.setScrollWorkBlock(evt, this.workNum =_index, title);
      this.hasMobileView = (this.work[this.workNum].mobile !==undefined) ? true : false;
      this.$store.commit("SETCODEPEN",(_index ===6) ? true : false);
    }
  },
  components: {
    Aside
  },
};
</script>

<style lang="scss">
$work: #1472c9;
$work: #e89836;
$workBg: #dadada;

$leftAmt: 960;

@keyframes skeletonPulse {
  0% {
    left: -10%;
  }
  100% {
    left: 100%;
  }
}

section.sect__work {
  .wrapper__work-header {
    display: flex;
    padding-left: 15%;
    height: 100px;
    justify-content: flex-start;
    align-items: flex-end;
    > h2 {
      color: orange;
      margin: 0px;
      text-align: left;
      display: inline;
      font-size: 90px;
      z-index: 2;
    }

    ol.list__itm-work {
      margin: 0px;
      margin-left: 4%;
      display: flex;
      height: 80px;
      padding: 0px;


      > li {
        display: inline;
        a {
          min-width: 95px;
          height: 40px;
          display: block;
          background: #eee;
          overflow: hidden;
          color: #333;
          margin-right: 10px;
          float: left;
          padding: 8px 0px 0px 12px;
          text-align: left;
          position: relative;
          &:before {
          content: attr(data-key);
           z-index: 99;
           height: 40px;
           display: flex;
           width:100%;
           position: absolute;
           align-items:center;
           justify-content: center;
           top:0px;
           left:0px;
          }
          &:after {
            content: "";
            display: block;
            width: 1px;
            height: 0px;
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%);
          }
          &:hover {
            cursor: pointer;
            transform: scale(1.025);
            transition: transform 0.1s ease-in;
            
            &:after {
              opacity: 0.3;
              background: orange;
              height: 40px;
              width: 100%;
              bottom: 0px;
              border-top:solid 1px orangeRed;
              transition: height 0.1s ease-in;
            }
          }
        }
        a.active {
          background: orange;
          
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .wrapper__work.buffer-white {
    &:before {
      position: relative;
    }
  }
  .wrapper__work {
    width: 100vw;
    min-height: 90vh;
    position: relative;
    display: flex;

    .block__work {
      width: 960px;
      overflow: hidden;
      position: relative;
      height: 100%;
      min-height: 600px;
      top: 0px;
      left: 0px;

      ul#list_skeleton {
        margin: 0px;
        padding: 0px;
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        transition: left 0.2s ease-in-out;
        > li {
          display: block;
          min-width: 960px;
          height: 600px;
          // background:$workBg;
          position: relative;
          .wrap__img-port{
            width: 640px;
            height: 440px;
            position: absolute;
            top: 60px;
            left: 50px;
            overflow: hidden;
            img.img-port {
                width:100%;
                height:100%;
                position: relative;
            }
          }
          
          div.work-sandbox{
            width: 640px;
            height: 440px;
            background:#ccc;
            position: absolute;
            top: 60px;
            left: 50px;
            z-index: 99;
            >ol{
              display:flex;
              margin:0px;
              padding:0px;
              flex-wrap:wrap;
              width:100%;
              position:relative;
              height:100%;
              >li{
                list-style:none;
                width:calc(25% - 1px);
                height:146px;
                margin-right:1px;
                margin-bottom:1px;
                >a{
                  display:block;
                  width:100%;
                  height:100%;
                  position: relative;
                  background:#333;
                 
                  &:hover{
                    *{
                      transform:scale(1.05) translateZ(900px);
                    }
                  
                    &:after{
                      background-color:#000;
                      z-index:999999;
                      box-shadow:0px 1px 1px #000;
                      transition:background 0.3s ease-in-out;
                      transform:translateX(-50%) scale(1.1);
                    }
                  }
                  &:after{
                    content:'codepen';
                    background-image:url('../assets/images/codepenIcon.svg');
                    background-repeat: no-repeat;
                    background-size: 14px 16px;
                    background-position:13px 4px;
                    opacity:.9;
                    display:block;
                    border:solid 1px #666;
                    border-radius:24px;
                    white-space: nowrap;
                    box-shadow:1px 1px 2px #000;
                    padding:5px 20px 5px 36px;
                    color:#fff;
                    position: absolute;
                    font:normal 10pt arial;
                    bottom:15px;
                    left:50%;
                    transform: translateX(-50%);
                  }
                  span{
                    font-size:11px;
                    color:#ccc;
                    padding:2px 0px 0px 4px;
                  }
                  h5{
                    position:relative;
                    color:#deeaf6;
                    font-size:110%;
                    margin-left:12px;
                    margin-right:2px;
                    margin-top:0px;
                    text-shadow:0px 1px 1px #000;
                  }
                }
              }
              >li:nth-child(4),
              >li:nth-child(8),
              >li:nth-child(12){
                margin-right:0px;
              }
            }
          }
        }
      }

      ul.skeleton {
        > li {
          > div {
            z-index: 999;
          }
        }
      }

      ul.skeleton {
        > li {
          position: relative;

          &:after {
            width: 50%;
            height: 100%;
            content: "";
            display: block;
            position: absolute;
            opacity: 0.1;
            top: 0px;
            left: -10%;
            background: linear-gradient(
              to left,
              transparent 0%,
              lighten($work, 30%) 30%,
              transparent 70%,
              transparent 100%
            );
            animation: skeletonPulse 1s infinite;
          }
        }
      }
      .view__work-wrap {
        position: absolute;
        display: none;
        height: 30px;
        top: 530px;
        min-width: 190px;
        left: 55px;
        p {
          display: block;
          margin: 0px;
          a {
            padding: 0px;
            margin: 0px;
            font-size: 13px;
            display: flex;
            height: 16px;
            color:#666;
            align-items: center;
            justify-content: flex-start;
            text-decoration: underline;
            &:after {
              content: attr(data-mode);
              margin-left:2px;
            }
            em {
              outline: none;
              appearance: none;
              display: block;
              width: 33px;
              height: 14px;
              margin-right: 4px;
              background: orange;
              background:#ccc;
              border-radius: 12px;
              position: relative;
              transform:scale(.8);
              transition:background .2s ease-in-out;
              &:hover {
                cursor: pointer;
              }
              &:after {
                content: "";
                width: 11px;
                height: 10px;
                position: absolute;
                display: block;
                border-radius: 14px;
                background: #fff;
                left: 2.5px;
                top: 2px;
                transition: left 0.1s ease-in-out;
              }
            }
          }
        }
      }
      .view__work-wrap.sho{
        display:block;
      }
      .view__work-wrap.mobile{
        p >a >em{
          background: #777;
          &:after {
            left: 19px;
          }
        }
      }
    }
  }
  .wrapper__work.hover {
    .wrap__work-outer {
      > aside {
        box-shadow: 0px 4px 28px #eee !important;
        transition:all .4s ease;
      }
      .block__work ul#list_skeleton >li img{
        box-shadow: -4px 4px 12px #ccc !important;
        transform:scale(1.03);
        transition:all .15s ease-in-out;
      }    
    }
  }
}


 
@mixin getLeft($num: 0) {
  left: #{- ($num * 960px) !important};
}

.wrapper__work.work1,
.wrapper__work.item1 {
  .wrap__work-outer .block__work > ul {
    @include getLeft(0);
  }
}

.wrapper__work.work2,
.wrapper__work.item2 {
  .wrap__work-outer .block__work > ul {
    @include getLeft(1);
  }
}
.wrapper__work.work3,
.wrapper__work.item3 {
  .wrap__work-outer .block__work > ul {
    @include getLeft(2);
  }
}
.wrapper__work.work4,
.wrapper__work.item4 {
  .wrap__work-outer .block__work > ul {
    @include getLeft(3);
  }
}

.wrapper__work.work5,
.wrapper__work.item5 {
  .wrap__work-outer .block__work > ul {
    @include getLeft(4);
  }
}

.wrapper__work.work6,
.wrapper__work.item6 {
  .wrap__work-outer .block__work > ul {
    @include getLeft(5);
  }
}
.wrapper__work.work7,
.wrapper__work.item7 {
  .wrap__work-outer .block__work > ul {
    @include getLeft(6);
  }
}
</style>
