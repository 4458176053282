<template>
  <div :class="['wrapper__process',numClass]">
    <h4>My Process</h4> 
    <ol :class="['mobile--list-process',numClass]">
      <li v-for="(itm,index) in process" :key="itm.name">
      <a @click="setNum($event,index+1)">{{index+1}}</a>
      </li>
 
    </ol>
    <div class="mobile--blocker">
    <ol :class="['list__skill-process',numClass]">
      <li v-for="itm in process" :key="itm.name">
        <dt>{{ itm.name }}</dt>
        <dd>{{ itm.desc }}</dd>
      </li>
    </ol>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SkillProcess",
  data(){
      return {
        numClass:"selected-1"
      }
  },
  methods:{
    setNum(evt,num){
      evt.preventDefault();
        this.numClass = "selected-"+num;
    }
   
  },
  computed: mapGetters({ process: ["GETSKILLPROCESS"] }),
};
</script>
<style lang="scss" scoped>
.wrapper__process {
  position: relative;
  padding-top: 60px;
  padding-left: 12%;
  overflow: hidden;
  height: 290px;
  width: 100%;
  h4 {
    font-size: 20px;
    margin-left: 35px;
    opacity: 0.5;
    text-transform: uppercase;
  }
}

ol.mobile--list-process{
  display:none;
  flex-direction: row;
  position:absolute;
  left:40px;
  top:4px;
  width:66vw;
  justify-content: space-between;
  
  >li{
    list-style:none;
    >a{
      display:flex;
      width:34px;height:34px;
      background:#999;
      border-radius:34px;
  
      outline:solid 4px #555;
      color:#111;
      justify-content:center;
      align-items:center;
    }
  }
}

ol.mobile--list-process.selected-1 li:nth-child(1),
ol.mobile--list-process.selected-2 li:nth-child(2),
ol.mobile--list-process.selected-3 li:nth-child(3),
ol.mobile--list-process.selected-4 li:nth-child(4),
ol.mobile--list-process.selected-5 li:nth-child(5){
      a{
        background:white;
        color:#222;
        outline:solid 2px white;
      }
}



ol.list__skill-process {
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
  list-style-type: lower-alpha;
  justify-content: space-between;
  max-width: 1050px;
  > li {
    padding-right: 30px;
    padding-top: 8px;
    padding-left: 0px;
    height: 40px;

    text-align: left;
    font-size: 15px;
    letter-spacing: 0.1em;
    height: 270px;

    width: 190px;
    list-style-type: numeric;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 180px;
      height: 4px;
      background: #ccc;
      top: -3px;
      left: -20px;
    }
    dt {
      width: 180px;

      text-transform: uppercase;
      margin: 0px;
      margin-bottom: 10px;
    }
    dd {
      line-height: 130%;
      letter-spacing: 0.05em;
      margin: 0px;
      margin-left: -20px;
      width: 185px;
      font-size: 14px;
      color: #ddd;
    }
  }
}
</style>