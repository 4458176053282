<template>
  <aside></aside>
</template>
<script>
export default {
  name: "title",
  data() {
    return {
      name: "sonnykoh"
    };
  },
};

</script>
<style lang="scss" scoped>

aside {
  position: absolute;
  left: 0px;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 9;
  width: 88px;
  background:#fbb000;
  transition:all .2s ease-in-out;

  &:before,&:after{
    width:15px;
    height:100%;background:#fff;
    content:'';
    position: absolute;
    top:0px;
    right:15px;
  }
  
  &:before{
    right:0px;
     background:#ccc;
  }

}
</style>