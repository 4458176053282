<template>
  <section class="sect sect__about" data-type="about">
    <div class="wrapper__about">
      <div class="flex flex-column pt20 fx3 mx-75">
        <h2>{{ title }}</h2>
        <ul class="flex">
          <li class="flex flex-column wide50 pl20">
            <h3>{{ title1 }}</h3>
            <div class="pr40">
              <p>{{ About.do.para1 }}</p>
              <p>{{ About.do.para2 }}</p>
              <Quotes />
            </div>
          </li>
          <li class="flex flex-column wide50 pl20">
            <h3>{{ title2 }}</h3>
            <div class="pr">
              <p>{{ About.am.para1 }}</p>
              <p>{{ About.am.para4 }}</p>
              <p>{{ About.am.para2 }} 
              Chicago <strike><i>Bulls</i></strike> 
            {{ About.am.para3 }}</p>
              <p>{{ About.am.para5 }}</p>
              <Edu />
            </div>
          </li>
        </ul>
        <Topfive />
      </div>
    </div>
  </section>
</template>

<script>
import Quotes from "./About-Quote";
import Edu from "./About-Edu";
import Topfive from "./About-Topfive";
import { mapGetters } from "vuex";

export default {
  name: "About",
  components: { Quotes, Edu, Topfive },
  computed: mapGetters({
    Quotes: ["GETQUOTES"],
       Edu: ["GETEDU"],
   Topfive: ["GETTOPFIVE"],
     About: ["GETABOUT"]
  }),
  data() {
    return {
      title: "About",
      title2: "Who I am",
      title1: "What I do",
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/global.scss";
$about: #222;
$aboutAside: #bc1742;
$h2_about: darken($aboutAside, 5%);

h3 {
  font-size: 75px;
}
h4 {
  font-size: 1.5rem;
}

ul {
  margin: 0px;
  padding: 0px;
}


.pr {
  @include pr;
}
.pr40 {
  @include pr(40px);
}
.pl {
  @include pl;
}
.pl20 {
  @include pl(20px);
}

.plr {
  @include plr;
}
.pt20 {
  padding-top: 20px;
}
.pt50 {
  padding-top: 50px;
}


section.sect__about {
  min-height: 200vh;
  opacity: 0.95;
  position: relative;
  overflow: visible;
  z-index: 1;
  display: flex;
  background: $aboutAside;
  flex-direction: column;
  .wrapper__about {
    display: flex;
    justify-content: center;
    color: $white;
    width: 100%;
    position: relative;
    div.mx-75 {
      max-width: 75vw;
    }

    &:after {
      content: "";
      display: block;
      width: 110%;
      min-width: 960px;
      position: absolute;
      height: 200px;
      top: -100px;
      left: 0px;
      background: linear-gradient(
        173deg,
        transparent,
        transparent 50%,
        #bc1742 50.01%,
        #bc1742 100%
      );
      transition: top .3s ease-in-out;
    }
  }


  h2 {
    font-size: 160px;
    position: absolute;
    top: 0%;
    transform: translate(-15px, -98%);
    color: $h2_about;
    z-index: 1;
    &::first-letter {
      transform: translateZ(-2);
      opacity: 0.8;
      z-index: -2;
    }
  }

  hr {
    margin: 30px 0px;
    height: 3px;
    width: 98%;
    border: 0px;
    position: relative;
    border-top: solid 1px darken($aboutAside, 10%);
    background: lighten($aboutAside, 10%);
  }
}

body.about section.sect__about .wrapper__about::after {
  top: -235px;
}
</style>
