<template>
  <div class="wrapper__topfive pl20 sect" data-type="about">
    <hr />
    <h4>{{ title }}</h4>
    <p class="txt_cite">
      {{ quote }}<br /><b>{{ author }}</b>
    </p>
    <ul class="list__top-five">
      <li v-for="itm in topfive" :key="itm.type">
        <div
          :class="['full-card', { focusScale: itm.scaled }]"
          @click="focusCard($event, itm)"
        >
          <div class="flipper">
            <div class="flip front">
              <h6>{{ itm.type }}</h6>
              <em :class="itm.type"></em>
            </div>
            <div class="flip back" @mouseleave="focusCard($event, itm)">
              <ol>
                <li v-for="listitem in itm.list" :key="listitem['name']">
                  <a rel="nofollow" target="_blank" :href="listitem['link']">{{
                    listitem["name"]
                  }}</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Topfive",
  data() {
    return {
      title: "My Top Five",
      author: "~ Nick Hornsby",
      quote:
        "It's not what you're like that's important; it's what you like that's important.",
    };
  },
  computed: mapGetters({ topfive: ["GETTOPFIVE"] }),
  methods: {
    focusCard: (evt, itm) => {
      if (evt.type === "mouseleave") itm.scaled = false;
      if (evt.type === "click") itm.scaled = !itm.scaled;
    },
  },
};
</script>

<style lang="scss" scoped>
$white: #fff;
$grey111: #111;
$about: #d82b60;
$greyEEE: #eee;
$grey333: #333;
$aboutTop: #bc1742;

.pl20 {
  padding-left: 20px;
}

.wrapper__topfive {
  position: relative;
  text-align: right;
  padding-bottom: 100px;
  h4 {
    opacity: 0.6;
  }
  .txt_cite {
    text-align: left;
    font: italic 17px/145% "Times New Roman";
  }

  ul.list__top-five {
    display: flex;
    width: 100%;
    max-width: 1280px;
    justify-content: flex-start;

    margin: auto;
    margin-top: 40px;
    > li {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      //width: calc(100% / 6.5%);//error fix;
      width: 160px;
      margin-right: 1%;
      font: normal 11px verdana;
    }
    >li:last-of-type{
      margin-right:0px;
    }
    > li.focusScale {
      box-shadow: 0px 0px 5px $grey111;
      .full-card {
        transform: scale(1.1);
        transition: transform 0.2s ease;
      }
    }
    ol {
      margin: 0px;
      padding: 0px;
      margin-left: 10px;
      li {
        line-height: 25px;
        color: $white;
        a {
          color: $white;
          display: inline-block;
          width: 100%;
          cursor: pointer;
          width: 100%;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.full-card {
  position: relative;
  width: 99.9%;
  height: 190px;

  &:hover {
    cursor: pointer;
    .flipper {
      transform: rotateY(-180deg);
    }
  }
}
.full-card.focusScale {
  z-index: 9999;
  transform: scale(1.4);
  transition: transform 0.2s ease;
  &:hover {
    .flipper {
      box-shadow: -1px 1px 4px darken($aboutTop, 40%);
      .flip.back {
        background: darken($about, 34%);
        transition: background 0.3s ease-in-out;
        ol {
          li {
            a {
              line-height: 20px;
              display: inline;
            }
          }
        }
        &:after {
          display: none;
        }
      }
    }
  }
}

$flipBorder: #ea4f78;

.flipper {
  position: relative;
  width: 160px;
  height: 190px;
  perspective: 1000px;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  transition: transform 0.5s ease;
  * {
    transform-style: preserve-3d;
  }

  .flip {
    position: absolute;
    width: 160px;
    height: 190px;
    padding: 20px 4px 20px 25px;
    top: 0px;
    left: 0px;
    color: $grey333;
    background: $greyEEE;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transform: rotateY(0deg);
    transition: transform 0.3s ease;
  }
  .flip.front {
    border-top: solid 5px $flipBorder;
    background: lighten($aboutTop, 4%);
    color: $white;
    h6 {
      font-size: 19px;
      position: relative;
      color: lighten($flipBorder, 20%);
      transform:translateZ(40px);
      left: -6px;
    }
    em {
      display: block;
      width: 100px;
      height: 130px;
      overflow: hidden;
      // border:solid 1px green;
      background-repeat: no-repeat;
      background-image: url(@/../../assets/images/favIcons__sprite.svg);
      background-size: 600px 100px;
      background-position: 7px 25px;
      position: absolute;
      bottom: 12px;
      opacity: 0.7;
      left: 50%;
      transform: translateX(-50%) scale(1.05) translateZ(45px);
    }
    em.la {
      background-position: -91px 20px;
    }
    em.books {
      background-position: -307px 20px;
    }
    em.tv {
      background-position: -414px 20px;
    }
    em.movies {
      background-position: -200px 23px;
    }
    em.albums {
      background-position: -515px 28px;
    }
  }
  .flip.back {
    transform: rotateY(-180deg);
    background: darken($aboutTop, 10%);
    position: relative;
    &:after {
      content: "";
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: transparent;
    }
  }
}

@media only screen and (max-width: 1200px) {
  body {
    .wrapper__topfive {
      ul.list__top-five {
        flex-wrap: wrap;
       >li {
           width:160px;
          margin-bottom: 15px;
          margin-right:1.5%;
        }
       
      
        .full-card,
        .flipper {
          width: 98%;
          .flip {
            width: 100%;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  body {
    .wrapper__topfive {
      ul.list__top-five {
        margin-right:0px;
       
        >li{
          width:35vw;
          margin-right:2.2%;
          .full-card{
            width:99.9%;
          }
          .full-card.focusScale{
            transform:scale(1.1) !important;
          }
        }
       
        >li:nth-child(even){
          margin-right:0px;
          clear:right;
        }
      }
    }
  }
}
</style>
