<template>
  <h2 class="head__contact">Contact</h2>
  <section
    :class="['sect', { sect__contact: sectBool, 'response-contact': thxBool }]"
    id="sect_contact"
    data-type="contact"
  >
    <div class="box__inner-contact">
      <h3
        id="h3_contact"
        :class="['head__contact', { 'contact-thx': thxBool }]"
      >
        {{ title }}<em></em>
      </h3>
      <form id="form_contact">
        <ul>
          <li :class="['li-form__validate', { err: emailObj[0].errBool }]">
            <label class="before__required" for="name">Name</label>
            <input
              name="name"
              class="inp-req"
              placeholder="name"
              v-model="emailObj[0].value"
              @keyup="checkErr('name')"
              type="text"
            />
          </li>
          <li :class="['li-form__validate', { err: emailObj[1].errBool }]">
            <label class="before__required" for="subject">Subject</label>
            <input
              name="subject"
              placeholder="subject"
              class="inp-req"
              v-model="emailObj[1].value"
              @keyup="checkErr('subject')"
              type="text"
            />
          </li>
          <li
            :class="[
              'li-form__validate',
              { err: emailObj[2].errBool },
              { errEmail: emailObj[2].errEmailBool },
            ]"
          >
            <label class="before__required" for="email">Email </label>
            <input
              name="email"
              placeholder="email"
              class="inp-req"
              v-model="emailObj[2].value"
              @keyup="checkErr('email')"
              type="text"
            />
          </li>

          <li :class="['li-form__validate', { err: emailObj[3].errBool }]">
            <label class="before__required" for="message">Message </label>
            <textarea
              name="message"
              class="inp-req"
              @keyup="checkErr('message')"
              v-model="emailObj[3].value"
            ></textarea>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              class="btn__send"
              @click="sendBtn($event)"
              >{{ btnLabel }}</a
            >
          </li>
        </ul>
      </form>
      <h4>
        <a :href="getToEmail()" class="link-emai" rel="nofollow">{{toEmail}}</a>
      </h4>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Contact",
  data() {
    return {
      errors: [],
      title: "Hello",
      btnLabel: "SEND",
      toEmail:"sonnykoh",
      thxBool: false,
      sectBool: true,
      emailObj: [
        { name: "name", value: "", errBool: false },
        { name: "subject", value: "", errBool: false },
        { name: "email", value: "", errBool: false, errEmailBool: false },
        { name: "message", value: "", errBool: false },
      ],
    };
  },

  methods: {
    ...mapActions({ homeScroll: "setActionScroll" }),
    resetEmailObj() {
      this.emailObj.forEach((itm) => {
        if (itm.name == "email") itm.errEmailBool = false;
        itm.value = "";
        itm.errBool = false;
      });
      return true;
    },
    playHarp(){
      let $harp= document.createElement("audio"),
          src = document.createElement("source");
          src.src = "http://www.sonnykoh.com/harp1.mp3";
          $harp.appendChild(src);
          $harp.load();  
          $harp.play();
    },
    getToEmail(){
      return "mailt:"+this.toEmail+"@"+this.toEmail+"."+"c"+"om";
    },
    validateContact() {
      let validBool = true;
      this.emailObj.forEach((itm) => {
        if (itm.value === "") {
          itm.errBool = true;
          validBool = false;
        }
        if (itm.value.indexOf("${") != -1 || itm.value.indexOf("script") != -1) {
          itm.errBool = true;
          validBool = false;
          window.location = "https://www.fbi.gov";
          return false;
        }
      
        if ((itm.name === "email" && itm.value.indexOf("@") == -1) || (itm.name === "email" && itm.value.indexOf(".") == -1)) {
          itm.errEmailBool = true;
          validBool = false;
        }
      });

      return validBool;
    },

    sendBtn(evt) {
      evt.preventDefault();
      let _self = this;
      if (_self.validateContact()) {

            _self.title = ", thanks";
            _self.obj = {"name": _self.emailObj[0].value,
                      "subject": _self.emailObj[1].value,
                        "email": _self.emailObj[2].value,
                      "message": _self.emailObj[3].value};

            let secQuest = prompt("how much is: 4 + 8 =?");
            let secAnswr = ['twelve','12'];
            if (!secAnswr.includes(secQuest)) {
               window.location = "https://www.fbi.gov";
              return false;
            }

                 fetch('/contactEmail.php', {
                               method: "POST",
                               body: JSON.stringify(_self.obj),
                               mode: 'cors',
                               cache: 'default',
                               headers: {'Content-Type': 'application/json'}
                }).then(() =>{
                    _self.thxBool = _self.resetEmailObj();
                   
                }).then(() =>{
                  setTimeout(() => {
                    _self.playHarp();
                    _self.homeScroll("/home");
                    }, 1000);
                })
          
          }

        return false;
      
    },
    checkErr(type) {
      let $itm = this.emailObj.find((itm) => itm.name == type);
      if ($itm.errBool == false && $itm.name !== "email") return;

      if (
        $itm.errEmailBool === true &&
        $itm.value.indexOf("@") != -1 &&
        $itm.value.indexOf(".") != -1
      ) {
        $itm.errEmailBool = false;
      }

      if ($itm.value.length > 1) $itm.errBool = false;
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/global.scss";
$error: #cc0000;
$say: #ffaa29;

.sect__contact {
  position: relative;
  min-height: 100vh;
  background: $contact;
  background: #444;
  padding-top: 60px;
  //overflow: hidden;
}

h2 {
  color: $contact;
  font-size: 40px;
  margin-left: 220px;
  margin-bottom: 0px;
  opacity: 0.75;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: $say;
}

.box__inner-contact {
  min-width: 640px;
  width: 50%;
  min-height: 330px;
  color: $white;
  padding: 35px 70px 20px 160px;
  height: auto;
  position: absolute;
  left: 26.6%;
  border: solid 1px #555;
  box-shadow: 10px 5px 40px #333;
  transition: transform 0.2s ease-in;
  h3.head__contact {
    font-size: 90px;
    line-height: 110%;
    font-weight: normal;
    color: #fff;
    width: 80%;
    max-width: 310px;
    position: relative;
    margin: auto auto 10px auto;
    text-align: left;

    &:before {
      content: "Say";
      color: $contactSay;
      position: absolute;
      left: -150px;
    }
  }

  h3.head__contact.contact-thx {
    &:before {
      content: "Ok";
      left: -100px;
    }
  }

  h4 {
    color: $contactSay;
    position: absolute;
    letter-spacing: 0.03em;
    font-size: 23px;
    top: 39%;
    left: -124px;
    a {
      color: $white;
      &:before {
        margin-right:5px;
        content: "sonnykoh @ ";
        color: $contactSay;
      }
      &:after {
        content: ".com";
      }
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
    opacity: 0.5;
  }
  &:after {
    content: "";
    display: block;
    width: 36px;
    height: 36px;
    position: absolute;
    background: #333;
    border-radius: 40px;
    z-index: 1;
    left: -18px;
    opacity: 0.4;
    top: 239px;
  }

  form {
    width: 86%;
    display: block;
    clear: both;
    float: right;

    > ul {
      width: 100%;
      min-height: 400px;
      display: block;
      margin-top: 0px;

      > li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
        min-height: 32px;
        clear: both;
        position: relative;
        label {
          display: block;
          text-align: left;
          width: 100%;
          height: 22px;
          overflow: hidden;
          margin-bottom: 3px;
          text-transform: capitalize;
        }
        label.before__required {
          position: relative;
          overflow: visible;
          &:before {
            content: "*";
            color: #ff0000;
            font-size: 14px;
            position: absolute;
            left: -10px;
            top: 0px;
          }
        }

        input,
        textarea {
          width: 100%;
          outline: none;
          border: 0;
          color: #333;
          max-width: 440px;
          height: 35px;
          background: $white;
          text-indent: 10px;
          &:focus {
            background: lighten($contact, 30%);
          }
        }
        textarea {
          height: 100px;
        }
      }

      > li.err,
      > li.err.errEmail,
      > li.errEmail {
        label {
          &:after {
            content: "required !";
            margin-top: 4px;
            font: normal 12px verdana, arial;
            position: absolute;
            color: #ff0000;
            right: 0px;
          }
        }
        input,
        textarea {
          outline: solid 1px $error !important;
          background: lighten($error, 49%) !important;
        }
      }

      > li.errEmail {
        label {
          &:after {
            content: "invalid email address";
          }
        }
      }

      > li:last-of-type {
        height: 90px;
        text-align: right;
        align-items: flex-start;
        margin-bottom: 0px;

        > .btn__send {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 220px;
          width: 100%;
          max-width:420px;
          height: 60px;
          box-shadow: 1px 2px 10px #222;
          background-color: #f59400;
          outline: none;
          color: #fff;
          font-weight: bold;
          font-size: 120%;
          overflow: hidden;
          position: relative;
          margin-top: 15px;
          margin-right: 0px;
          border-radius: 40px;
          z-index: 0;
          cursor: pointer;
          &:focus {
            margin-top: 17px;
          }
          &:blur {
            margin-top: 15px;
          }
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            opacity: 0.7;
            z-index: -1;
            border-radius: 40px;
            background: transparent;
            transform: translate(-50%, -50%);
          }

          &:hover {
            cursor: pointer;
            &:after {
              background: $say;
              mix-blend-mode: screen;
              width: 100%;
              height: 60px;
              transition: all 0.2s ease-in-out;
            }
          }
        }
      }
    }
  }
}

$cardThx: #e94837;
.wrapper__contact-response {
  position: relative;
  display: inline;
  background: #fff;
  top: 0px;
  opacity: 0;
  left: 210px;
  right: 0px;
  bottom: 0px;
  z-index: 999999;
}

@keyframes cardPulse {
  0% {
    background: #f59400;
    transform: scale(1.01);
    transition: all 4.5s ease;
  }
  100% {
    background: $cardThx;
    transform: scale(1.01);
    transition: all 8s ease;
  }
}
</style>
