<template>
  <router-view/>
</template>
<script>

export default {
  name:"App",
  created(){
    document.getElementsByTagName('body')[0].setAttribute('id','body');
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
